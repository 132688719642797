export const getPortalUrlFromId = (id) => {
  switch (id) {
    case "1": // notícias
      return "/";
    case "2": // Gestão
      return "/sie";
    case "3": // Transparência
      return "/transparencia";
    case "4": // Institucional
      return "/institucional";
    case "5": // CBTKDeduca
      return "/cbtkdeduca";
    default:
      return "/";
  }
};
