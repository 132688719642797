import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/MultiLineText.css";
import "../styles/SmartInput.css";
import "../styles/SmartInputNoAnimation.css";
import { createKey } from "../utils/KeysUtil";

class SmartInput extends React.Component {
  inputID = 0;
  constructor(props) {
    super(props);
    if (this.props.id === undefined) {
      this.inputID = createKey();
    } else {
      this.inputID = this.props.id;
    }
    // if (this.props.inputRef === undefined) {
    //   this.props.inputRef = React.createRef(null);
    // }
    this.focusMethod = this.focusMethod.bind(this);
  };

  focusMethod = function getFocus() {
    // //console.log("focusMethod");
    // if (this.props.inputRef !== undefined) {
    //   this.props.inputRef.current.focus();
    // } else {
    let elementToFocus = document.getElementById(this.inputID);
    // //console.log("focusMethod => elementToFocus", elementToFocus);
    if (elementToFocus) elementToFocus.focus();
    // }
    if (this.props.onFocus) this.props.onFocus();
  };

  classNameWithRequiredDecoration = () => {
    if (this.props.noAnimation) {
      if (this.props.required && !this.props.disabled) {
        return "g-input-no-animation g-input-no-animation-required-border";
      } else {
        return "g-input-no-animation";
      }
    } else {
      if (this.props.required && !this.props.disabled) {
        return "g-input g-input-required-border";
      } else {
        return "g-input";
      }
    }
  };

  onChangeEventLocal = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
    // document.getElementById(this.props.id).focus();
    // event.target.focus();
  };

  render() {
    if (this.props.type === "color") {
      return (
        <>
          <div className={this.classNameWithRequiredDecoration()}>
            <input
              id={this.inputID}
              ref={this.props.inputRef}
              value={this.props.value}
              onBlur={this.props.onBlur}
              onChange={this.props.onChange}
              // onChange={this.onChangeEventLocal}
              onFocus={this.props.onFocus}
              onKeyDown={this.props.onKeyDown}
              type={this.props.type}
              // className={this.classNameWithExtras()}
              className={this.props.className}
              autoComplete={this.props.autoComplete}
              placeholder="&nbsp;"
              // required
              data-indx={this.props.indx}
              maxLength={this.props.maxLength}
              disabled={this.props.disabled ? this.props.disabled : false}
              title={this.props.title}
              style={{ minHeight: "94px", maxHeight: "94px" }}
            />
            {this.props.placeholder && (
              <label
                onClick={this.focusMethod}
                onFocus={this.focusMethod}
                title={this.props.title}
              >
                {this.props.placeholder} {this.props.required && !this.props.disabled ? "*" : ""}
              </label>
            )}
          </div>
        </>
      );
    } else if (this.props.type === "textarea") {
      return (
        <>
          <div className={this.classNameWithRequiredDecoration()}>
            <textarea
              id={this.inputID}
              ref={this.props.inputRef}
              value={this.props.value}
              onBlur={this.props.onBlur}
              onChange={this.props.onChange}
              onFocus={this.props.onFocus}
              // onKeyDown={this.props.onKeyDown}
              // type={this.props.type}
              rows={this.props.rows}
              cols={this.props.cols}
              // className={this.classNameWithExtras()}
              className={this.props.className}
              autoComplete={this.props.autoComplete}
              placeholder="&nbsp;"
              // required
              data-indx={this.props.indx}
              maxLength={this.props.maxLength}
              disabled={this.props.disabled ? this.props.disabled : false}
            />
            {this.props.placeholder && (
              <label onClick={this.focusMethod} onFocus={this.focusMethod}>
                {this.props.placeholder} {this.props.required && !this.props.disabled ? "*" : ""}
              </label>
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={this.classNameWithRequiredDecoration()}>
            <input
              id={this.inputID}
              ref={this.props.inputRef}
              value={this.props.value}
              onBlur={this.props.onBlur}
              onChange={this.props.onChange}
              // onChange={this.onChangeEventLocal}
              onFocus={this.props.onFocus}
              onKeyDown={this.props.onKeyDown}
              type={this.props.type}
              // className={this.classNameWithExtras()}
              className={this.props.className}
              autoComplete={this.props.autoComplete}
              placeholder="&nbsp;"
              // required
              data-indx={this.props.indx}
              maxLength={this.props.maxLength}
              disabled={this.props.disabled ? this.props.disabled : false}
              title={this.props.title}
            />
            {this.props.placeholder && (
              <label
                onClick={this.focusMethod}
                onFocus={this.focusMethod}
                title={this.props.title}
              >
                {this.props.placeholder} {this.props.required && !this.props.disabled ? "*" : ""}
              </label>
            )}
          </div>
        </>
      );
    }
  }
}

export default SmartInput;
