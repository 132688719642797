export const generateTrigam = (value) => {
  if (value.length > 0) {
    value = value.trim();
    value = value.toLowerCase();
    value = value.replaceAll("  ", " "); //Retirar espaço duplo;

    let trigam = [];
    if (value) {
      let words = value.split(" ");
      trigam = [...words];
      for (let i = 0; i < value.length - 2; i++) {
        trigam.push(value.substring(i, value.length));
      }
      for (let i = 1; i < value.length - 2; i++) {
        trigam.push(value.substring(0, value.length - i));
      }
    }
    return trigam;
  } else {
    return [];
  }
};

// export const generateTrigam = (value) => {
//   if (value.length > 0) {
//     value = value.toLowerCase();
//     let trigam = [];
//     if (value) {
//       let words = value.split(" ");
//       words.forEach((word) => {
//         if (word.length > 3) {
//           for (let i = 0; i < word.length - 2; i++) {
//             trigam.push(word.substring(i, i + 3));
//           }
//         } else {
//           trigam = [...trigam, word];
//         }
//       });
//     }
//     return trigam;
//   } else {
//     return [];
//   }
// };
