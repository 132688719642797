export function uniq(a) {
  var seen = {};
  return a.filter(function (item) {
    return seen.hasOwnProperty(item) ? false : (seen[item] = true);
  });
}

export function joinArrays(array1, array2) {
  if (array1 === undefined) array1 = [];
  if (array2 === undefined) array2 = [];
  return [...array1, ...array2];
}

export function hasAllArrayElements(array, array2) {
  return array.every((item) => array2.includes(item));
}

export const shuffle = (array) => { 
  return array.sort(() => Math.random() - 0.5); 
}; 

export const shuffle2 = (array) => { 
  for (let i = array.length - 1; i > 0; i--) { 
    const j = Math.floor(Math.random() * (i + 1)); 
    [array[i], array[j]] = [array[j], array[i]]; 
  } 
  return array; 
}; 

export const joinArrayInRandomPosition = (array, elements) => {
  const randomPosition = Math.floor(Math.random() * array.length);
  array.splice(randomPosition, 0, ...elements);
  return array;
}