export const GraduacaoDanModelo = (indxGraduacao) => {
  return {
    nivel: indxGraduacao,
    data: "",
    aprovacaoFederacao: false,
    observacaoFederacao: "",
    aprovacaoConfederacao: false,
    observacaoConfederacao: "",
    comprovanteAnexo: "",
  };
};
