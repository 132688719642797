import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  let location = useLocation();
  // //console.log("currentUser=", currentUser);
  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
  // if (currentUser.emailVerified) {
  //   return children;
  // } else {
  //   return <Navigate to="/notverified" state={{ from: location }} />;
  // }
};

export default ProtectedRoute;
