import React, { Fragment } from "react";
// import EntidadesService from "../../services/Entidades.Service";
// import certificationService from "../../services/certification.service";
import BusySpinner, { showSpinner, hideSpinner } from "../../BusySpinner";
import { createKey } from "../../../utils/KeysUtil";
// import { formatDataByType } from "../../utils/FormatDataTypesUtils";
import QRCode from "react-qr-code";
// import { getUrlDetails } from "../../utils/UrlDomainUtil";
import { format } from "date-fns";
// import { getMonth } from "../../utils/monthUtil";
import "../../../styles/Certificacao.css";
// import contasService from "../../services/contas.service";
import GenericService from "../../../services/Generic.service";
// import { Timestamp } from "firebase/firestore";
import { getMonth } from "../../../utils/monthUtil";

class PrintCredentialComponent extends React.Component {
  state = {
    error: "",
    arteCredencial: "https://firebasestorage.googleapis.com/v0/b/sg-cbtkd.appspot.com/o/credenciais%2Fcredencial.jpg?alt=media&token=31828222-beac-4106-832b-3c85edd658cc",
    credencial: {
      idInscricao: "",
      nome: "",
      federacao: "",
      logradouroUF: "",
      modalidade: "",
      classe: "",
      peso: "",
      urlFoto: "",
    }
  };
  queryParams = new URLSearchParams(window.location.search);

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    showSpinner();
    let imagemPerfil = (this.props.inscricao.imagemPerfil === undefined ? "" : this.props.inscricao.imagemPerfil);
    if (imagemPerfil === "") {
      await GenericService.getOne("praticantes", this.props.inscricao.uid)
        .then((praticante) => {
          if (praticante) {
            imagemPerfil = (praticante.dadosPessoais.urlImagemID.indexOf("http") !== -1) ? praticante.dadosPessoais.urlImagemID : "";
          }
          this.setState((prevState) => ({
            ...prevState,
            credencial: {
              id: this.props.inscricao.id,
              name: this.props.inscricao.name,
              vinculadoAEntidade: this.props.inscricao.vinculadoAEntidade,
              federacao: this.props.inscricao.federacao,
              logradouroUF: this.props.inscricao.logradouroUF,
              country: this.props.inscricao.country,
              sexo: this.props.inscricao.sexo,
              modalidade: this.props.inscricao.modalidade,
              classe: this.props.inscricao.classeSelecionada,
              peso: this.props.inscricao.peso,
              urlFoto: imagemPerfil,
            },
          }));
          hideSpinner();
        }).catch((err) => {
          console.log(err);
          this.setState((prevState) => ({ ...prevState, error: err.message }));
          hideSpinner();
        });
    }
    else {
      this.setState((prevState) => ({
        ...prevState,
        credencial: {
          id: this.props.inscricao.id,
          name: this.props.inscricao.name,
          vinculadoAEntidade: this.props.inscricao.vinculadoAEntidade,
          federacao: this.props.inscricao.federacao,
          logradouroUF: this.props.inscricao.logradouroUF,
          country: this.props.inscricao.country,
          sexo: this.props.inscricao.sexo,
          modalidade: this.props.inscricao.modalidade,
          classe: this.props.inscricao.classeSelecionada,
          peso: this.props.inscricao.peso,
          urlFoto: imagemPerfil,
        },
      }));
      hideSpinner();
    }
  };

  extraClassName = "";

  render() {
    // //console.log("render()");
    // console.log("this.state.certificado=", this.state.certificado ? this.state.certificado : "undefined");
    return (
      < >
        {(this.state.certificado && this.state.federacao) ? (
          <Fragment key={createKey()}>
            <div
              // className="m-4 card p-4 certificado_bgimg"
              className="p-0 ms-0 mt-0 mb-0 me-0"
              // style={{ minHeight: "957px", maxHeight: "957px", minWidth: "1354px", maxWidth: "1354px", backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/sg-cbtkd.appspot.com/o/modeloscertificados%2FCBTKD_certificado_modelo1_branco.png?alt=media&token=64e30a70-abbf-48b4-b4d2-e47a3f1706e5)" }}
              // style={{ minHeight: "957px", maxHeight: "957px", minWidth: "1354px", maxWidth: "1354px", backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/sg-cbtkd.appspot.com/o/modeloscertificados%2FModelo_Certificado_CBTKD.jpeg?alt=media&token=bd062c53-c056-4e6f-b459-9b8f545d7431)" }}
              style={{
                minHeight: "1241px", maxHeight: "1241px", minWidth: "1714px", maxWidth: "1714px",

                backgroundImage: `url(${this.state.arteCredencial})`,
                backgroundRepeat: "no-repeat",
                // backgroundSize: "1223px 865px",
                backgroundColor: "gray",
                // backgroundSize: "1278px 904px",
                backgroundSize: "cover",

                // scale: "0.54",
              }}
            >
              <div className="d-flex flow-row flow-nowrap">
                <div className={`text-center col text-black fs-4 fw-bold mb-4 w-100 ${this.extraClassName}`} style={{ marginLeft: "558px", marginTop: "412px", minWidth: "735px", maxWidth: "735px" }}>
                  {this.state.certificado.vinculo.name.toUpperCase()}
                </div>
              </div>
              <div className="d-flex flow-row flow-nowrap">
                <div className={`text-center col text-black mb-4 w-100 ${this.extraClassName}`} style={{ marginLeft: "1075px", marginTop: "5px", minWidth: "80px", maxWidth: "80px", fontSize: "16px" }}>
                  {this.state.certificado.dadosCertificacao.graduacao.toUpperCase()}
                  {/* 1º DAN */}
                </div>
              </div>
              <div className="d-flex flow-row flow-nowrap" style={{ marginTop: "-38px" }}>
                <div
                  className={`text-center col text-black mb-4 ps-2 ${this.extraClassName}`}
                  style={{ marginLeft: "760px", marginTop: "34px", minWidth: "165px", maxWidth: "165px", fontSize: "16px" }}
                >
                  {this.state.certificado.numero}
                </div>
                <div
                  className={`text-center col text-black mb-4 ps-2 ${this.extraClassName}`}
                  style={{ marginTop: "34px", minWidth: "185px", maxWidth: "185px" }}
                ></div>
                <div className={`text-center col text-black mb-4 pe-4 ${this.extraClassName}`} style={{ marginTop: "34px", minWidth: "185px", maxWidth: "185px", fontSize: "16px" }}>
                  {format(this.state.certificado.dadosCertificacao.dataEmissao.toDate(), "dd/MM/yyyy")}
                </div>
              </div>
              <div className="d-flex flow-row flow-nowrap">
                <div className={`text-center col text-black mb-4 w-100 ${this.extraClassName}`} style={{ marginLeft: "585px", marginTop: "6px", minWidth: "710px", maxWidth: "710px", fontSize: "16px" }}>
                  {this.state.federacao.toUpperCase()}
                </div>
              </div>
              <div className="d-flex flow-row flow-nowrap" >
                <div
                  className={`text-center col text-black mb-4 ${this.extraClassName}`}
                  style={{ marginLeft: "677px", marginTop: "14px", minWidth: "84px", maxWidth: "84px", fontSize: "16px" }}
                >
                  {format(this.state.certificado.dadosCertificacao.dataEmissao.toDate(), "dd")}
                </div>
                <div
                  className={`text-center col text-black mb-4 ${this.extraClassName}`}
                  style={{ marginLeft: "28px", marginTop: "14px", minWidth: "115px", maxWidth: "115px", fontSize: "16px" }}
                >
                  {getMonth(format(this.state.certificado.dadosCertificacao.dataEmissao.toDate(), "M")).toUpperCase()}
                </div>
                <div
                  className={`text-center col text-black mb-4 ${this.extraClassName}`}
                  style={{ marginLeft: "28px", marginTop: "14px", minWidth: "152px", maxWidth: "152px", fontSize: "16px" }}
                >
                  {format(this.state.certificado.dadosCertificacao.dataEmissao.toDate(), "yyyy")}
                </div>
              </div>
              <div className="d-flex flow-row flow-nowrap">
                <div className="col border border-1 p-2" style={{ marginLeft: "1217px", marginTop: "95px", minWidth: "146px", maxWidth: "146px", transform: "translateY(-752px)" }}>
                  <QRCode value={window.location.href.replace("/sie/servicos/certificados", "/validacao/certificado")} size={128} />
                </div>
              </div>
            </div>
          </Fragment >
        ) : (
          ""
        )
        }
        <BusySpinner />
      </>
    );
  }
}

export default PrintCredentialComponent;
