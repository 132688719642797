import { GraduacaoDanModelo } from "../components/entidades/praticantes/models/graduacaoDan.modelo copy";

export const maxGraduacaoDescription = (maxGraduacao) => {
  // console.log("maxGraduacao", maxGraduacao);
  let faixa = "º Gub";
  if (maxGraduacao) {
    if (maxGraduacao > 10) {
      maxGraduacao -= 10;
      faixa = "º Dan";
    } else {
      maxGraduacao = 11 - maxGraduacao;
    }
    return maxGraduacao + faixa;
  } else {
    return "";
  }
};

export const getmaxGraduacao = (historicoGraduacoes, HistoricoGraduacoes) => {
  // console.log("getmaxGraduacao", historicoGraduacoes, HistoricoGraduacoes);
  let returnValue = -1;
  if (historicoGraduacoes !== undefined) {
    for (let i = historicoGraduacoes.length - 1; i >= 0; i--) {
      if (i <= 9) {
        // console.log("getmaxGraduacao => historicoGraduacoes[", i, "]", historicoGraduacoes[i]);
        if (historicoGraduacoes[i] !== "") {
          if (returnValue < i + 1) {
            returnValue = i + 1;
            break;
          }
        }
      } else {
        // console.log("getmaxGraduacao => historicoGraduacoes[", i, "]", historicoGraduacoes[i], historicoGraduacoes[i].data !== "", historicoGraduacoes[i].aprovacaoConfederacao === true);
        historicoGraduacoes[i] = safeGraduacaoConformity(historicoGraduacoes[i], i);
        if (historicoGraduacoes[i].data !== "" && (historicoGraduacoes[i].aprovacaoConfederacao === true)) {
          // console.log("getmaxGraduacao => historicoGraduacoes[", i, "]", historicoGraduacoes[i]);
          if (returnValue < i + 1) {
            returnValue = i + 1;
            break;
          }
        }
        // console.log("getmaxGraduacao => historicoGraduacoes[", i, "]", historicoGraduacoes[i], historicoGraduacoes[i].data !== "", historicoGraduacoes[i].aprovacaoConfederacao === true);
      }
    }
  } else if (HistoricoGraduacoes !== undefined) {
    for (let i = HistoricoGraduacoes.length - 1; i >= 0; i--) {
      if (HistoricoGraduacoes[i] !== "") {
        if (returnValue < i + 1) {
          returnValue = i + 1;
          break;
        }
      }
    }
  }
  // console.log("getmaxGraduacao => returnValue", returnValue);
  return returnValue;
};

const safeGraduacaoConformity = (graduacao, indxGraduacao) => {
  if (graduacao === undefined) return GraduacaoDanModelo(indxGraduacao);
  if (graduacao === null) return GraduacaoDanModelo(indxGraduacao);
  if (graduacao === "") return GraduacaoDanModelo(indxGraduacao);
  return {
    ...GraduacaoDanModelo(indxGraduacao),
    ...graduacao
  };
}