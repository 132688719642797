import { Timestamp } from "firebase/firestore";

export function todayZeroHour() {
  return new Date(new Date().setHours(0, 0, 0, 0));
}

export function timestampTodayZeroHour() {
  return Timestamp.fromDate(new Date(new Date().setHours(0, 0, 0, 0)));
}

export function timestampTodayLastTime() {
  return Timestamp.fromDate(new Date(new Date().setHours(20, 59, 59, 999)));
}

export function timestampZeroHourFromAddDays(date, days) {
  //console.log(
  //   "timestampZeroHourFromAddDays(",
  //   date,
  //   ", ",
  //   days,
  //   ")=",
  //   Timestamp.fromDate(addDays(new Date(date.setHours(0, 0, 0, 0)), days)).toDate()
  // );
  return Timestamp.fromDate(addDays(new Date(date.setHours(0, 0, 0, 0)), days));
}

export function timestampLastTimeFromAddDays(date, days) {
  //console.log(
  //   "timestampLastTimeFromAddDays(",
  //   date,
  //   ", ",
  //   days,
  //   ")=",
  //   Timestamp.fromDate(addDays(new Date(date.setHours(20, 59, 59, 999)), days)).toDate()
  // );
  return Timestamp.fromDate(addDays(new Date(date.setHours(20, 59, 59, 999)), days));
}

// export function subDays(date, days) {
//   const copy = new Date(Number(date));
//   copy.setDate(date.getDate() - days);
//   return copy;
// }

export function addDays(date, days) {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
}

export function timeStampFromAddDays(date, days) {
  return Timestamp.fromDate(this.addDays(date.toDate(), days));
}
