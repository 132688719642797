// import { Timestamp } from "@google-cloud/firestore";
import { Timestamp } from "firebase/firestore";

export const UserModelo = (currentUser) => {
  return {
    dadosPessoais: {
      name: "",
      searchableName: "",
      trigam: [],
    },
    dadosContato:{
      email: "",
      celular: ""
    },
    metadados: {
      ownerid: currentUser ? currentUser.uid ? currentUser.uid : undefined : undefined,
      owner: currentUser ? currentUser.displayName ? currentUser.displayName : undefined: undefined,
      creation: "", // Timestamp.fromDate(new Date()),
      date: "", //Timestamp.fromDate(new Date()),
      successorId: "",
      version: 0,
      active: true,
    },
    usersgroupsowned: [],
  };
};
