import { format } from "date-fns";
import currencyFormat from "../utils/NumberFormatsUtil";
import { getMonthCompact } from "./monthUtil";

export function compactDate(value) {
  if (value) {
    if (value.getFullYear() === new Date().getFullYear()) {
      return getMonthCompact(format(value, "M")) + format(value, ". dd");
    } else {
      return getMonthCompact(format(value, "M")) + format(value, ". dd, yyyy");
    }
  } else {
    return value;
  }
}
