import React, { useEffect, useState, Fragment, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import SmartInput from "../SmartInput";
// import HomeIcon from "@mui/icons-material/Home";
import BusySpinner, { showSpinner, hideSpinner } from "../BusySpinner";
import DeleteRegisterDialog from "../DeleteRegisterDialog";
import { useAuth } from "../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { compactDate } from "../../utils/noticiasUtil";
import { createKey } from "../../utils/KeysUtil";
import { getPortalUrlFromId } from "../../utils/portaisUtil";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/UsersGroupsPage.css";
import "../../styles/SearchListCollections.css";
import "../../styles/MaterialFAB.css";
import "../../styles/ModalSpinner.css";
import "../../styles/noticias.css";
import "../../styles/SmartElement.css";
import GenericVinculadoService from "../../services/GenericVinculado.service";
import { removeAccents } from "../../utils/removeAccents";
import {
  orderBy,
  limit,
  where,
  startAfter,
} from "firebase/firestore";

export default function NoticiasSearchComponentVersion2(props) {
  const currentSubDomainID = useSelector((state) => {
    // //console.log("state=", state);
    // //console.log("state.subDomain=", state.subDomain);
    return state.subDomain.id;
  });
  // const currentSubDomainName = useSelector((state) => {
  //   // //console.log("state.subDomain=", state.subDomain);
  //   if (state.subDomain.data === undefined) {
  //     return undefined;
  //   } else {
  //     return state.subDomain.data.name;
  //   }
  // });

  const { currentUser, domainDetails } = useAuth();
  const [selectedItem, setSelectedItem] = useState(undefined);
  const CREATERULE = props.CREATERULE;
  const RETRIEVERULE = props.RETRIEVERULE;
  const UPDATERULE = props.UPDATERULE;
  const DELETERULE = props.DELETERULE;
  const STATERULE = props.STATERULE;

  // useEffect(() => {
  //   if (documents === undefined && !loading) {
  //     setLoading(true);
  //     ResetSearch();
  //   }
  // }, [currentSubDomainID]);

  useEffect(() => {
    if (!loading) {
      setLoadingForCurrentUser(true);
      ResetSearch();
    }
  }, [domainDetails]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.uid && !loadingForCurrentUser) {
        setLoadingForCurrentUser(true);
        ResetSearch();
      }
    }
  }, [currentUser]); //[currentUser.rulesOwned]);

  const createEnabled = () => {
    return currentUser ? (currentUser.rulesOwned ? currentUser.rulesOwned.includes(CREATERULE) : false) : false;
  };
  const retrieveEnabled = () => {
    return currentUser ? (currentUser.rulesOwned ? currentUser.rulesOwned.includes(RETRIEVERULE) : false) : false;
  };
  const updateEnabled = () => {
    return currentUser ? (currentUser.rulesOwned ? currentUser.rulesOwned.includes(UPDATERULE) : false) : false;
  };
  const deleteEnabled = () => {
    return currentUser ? (currentUser.rulesOwned ? currentUser.rulesOwned.includes(DELETERULE) : false) : false;
  };
  const stateEnabled = () => {
    return currentUser ? (currentUser.rulesOwned ? currentUser.rulesOwned.includes(STATERULE) : false) : false;
  };

  // let navigate = useNavigate();

  const [dados, setDados] = useState({
    documents: undefined,
    firstVisible: {},
    lastVisible: null,
    collectionSize: 0,
  });

  const [error, setError] = useState("");
  const [documents, setDocuments] = useState(undefined);
  // const [firstVisible, setFirstVisible] = useState({});
  // const [lastVisible, setLastVisible] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  // const [prevButtonEnabled, setPrevButtonEnabled] = useState(false);
  // const [nextButtonEnabled, setNextButtonEnabled] = useState(true);
  // const [collectionSize, setCollectionSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchInfo, setSearchInfo] = useState("");
  const [showAllVersions, setShowAllVersions] = useState(false);
  const [showActivesInactives, setShowActivesInactives] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingForCurrentUser, setLoadingForCurrentUser] = useState(false);

  useEffect(() => {
    if (documents === undefined) ResetSearch();
  }, []);

  useEffect(() => {
    handleSwitchActiveSecurity();
  });

  useEffect(() => {
    ResetSearch();
  }, [showAllVersions]);

  useEffect(() => {
    ResetSearch();
  }, [showActivesInactives]);

  useEffect(() => {
    ResetSearch();
  }, [pageSize]);

  const getSearchTarget = () => {
    //console.log("getSearchTarget => searchInfo=", searchInfo);
    if (["%", "*", "?"].includes(searchInfo.substring(0, 1))) {
      let target = props.searchTarger.split(".");
      target[target.length - 1] = "trigam";
      //console.log("target=", target);
      return target.join(".");
    } else return props.searchTarger ? props.searchTarger : "title";
  };

  const handleSearch = useCallback(async (lastVisible) => {
    if (!domainDetails) return false;
    //console.log("handleSearch");
    showSpinner();
    if (error) setError("");
    let conditionsCBTKD = [];
    conditionsCBTKD.push(where("contextoExibicaoNoticias", "==", true));
    conditionsCBTKD.push(where("vinculo.idEntidade", "==", domainDetails.domainId));
    let searchInfoConditions = [...GenericVinculadoService.getConditionsFromQueryFilter(getSearchTarget(), removeAccents(searchInfo.trim().toLowerCase()))];
    if (searchInfoConditions.length > 0) conditionsCBTKD.push(...searchInfoConditions);
    conditionsCBTKD.push(where("metadados.active", "==", true));
    conditionsCBTKD.push(where("metadados.successorId", "==", ""));
    conditionsCBTKD.push(limit(pageSize));
    conditionsCBTKD.push(orderBy("dtNoticia", "desc"));

    if (lastVisible !== undefined && lastVisible !== null && lastVisible !== "") conditionsCBTKD.push(startAfter(lastVisible));

    console.log("conditionsCBTKD=", conditionsCBTKD);
    GenericVinculadoService.getCollectionByConditions("conteudos", conditionsCBTKD)
      .then(async (docs) => {
        console.log("docs=", docs);
        if (docs) {
          setCurrentPage(1);
          setDocuments(docs[0]);
          if (docs.length > 0) {
            setDados((prevState) => ({
              ...prevState,
              documents: (lastVisible !== undefined && lastVisible !== null && lastVisible !== "") ? [...prevState.documents, ...docs[0]] : docs[0],
              firstVisible: docs[0][0],
              lastVisible: docs[0][docs[0].length - 1],
              collectionSize: docs[1],
            }));
          }
        }
        hideSpinner();
      })
      .catch((err) => {
        console.log(err);
        setError(err.message);
        hideSpinner();
      });
    // }
  });

  const ResetSearch = useCallback(async () => {
    if (!domainDetails) return false;
    setDados({
      documents: [],
      firstVisible: {},
      lastVisible: null,
      collectionSize: 0,
    });
    handleSearch();
  });

  useEffect(() => {
    if (documents === undefined) ResetSearch();
  }, []);

  useEffect(() => {
    handleSwitchActiveSecurity();
  });

  useEffect(() => {
    ResetSearch();
  }, [showAllVersions]);

  useEffect(() => {
    ResetSearch();
  }, [pageSize]);

  const retriveMoreData = async () => {
    handleSearch(dados.lastVisible);
  };

  const handleSwitchActiveSecurity = () => {
    Array.from(document.getElementsByClassName("form-check-input")).map((element) => {
      element.disabled = !stateEnabled;
    });
  };

  const ShowAllVersionsChanged = (e) => {
    //e.preventDefault();
    setShowAllVersions(e.target.checked);
  };

  const ShowActivesInactivesChanged = (e) => {
    //e.preventDefault();
    setShowActivesInactives(e.target.checked);
  };

  const handleExcluir = (e) => {
    e.preventDefault();
    // //console.log("handleExcluir => e.target=", e.target);
    setSelectedItem(e.target.dataset.id);
  };

  const handleDialogExcluir = (e) => {
    e.preventDefault();
    // //console.log("handleDialogExcluir => e.target=", e.target);
    //console.log("handleDialogExcluir => selectedItem=", selectedItem);
    //console.log("handleDialogExcluir => props.collectionName=", props.collectionName);
    if (selectedItem) {
      if (props.DataServiceNaoVinculado) {
        props.DataServiceNaoVinculado.delete(props.collectionName, selectedItem)
          .then(() => {
            //console.log("Dado excluído com sucesso!");
            ResetSearch();
            // navigate("/noticias");
          })
          .catch((err) => {
            //console.log(err);
            setError(err.message);
          });
      } else {
        props.dataService
          .delete(props.collectionName, selectedItem)
          .then(() => {
            //console.log("Dado excluído com sucesso!");
            ResetSearch();
            // navigate("/noticias");
          })
          .catch((err) => {
            //console.log(err);
            setError(err.message);
          });
      }
    }
  };

  const handleSearchIcon = (e) => {
    // e.preventDefault();
    if (currentPage > 0) {
      //console.log("handleSearchIcon => searchInfo =", searchInfo);
      if (searchInfo.length > 0) {
        handleSearch();
      } else {
        ResetSearch();
      }
    }
  };

  const handleEnterKeyDown = (e) => {
    // e.preventDefault();
    // //console.log("handleEnterKeyDown => e.key=", e.key);
    if (e.key === "Enter") {
      if (currentPage > 0) {
        if (searchInfo.length > 0) {
          handleSearch();
        } else {
          ResetSearch();
        }
      }
    }
  };

  const handleInputChange = (event) => {
    // event.preventDefault();
    setSearchInfo(event.target.value);
  };

  // if (documents === undefined) return <div>Carregando...</div>;
  // else
  return (
    <>
      <div className="container-fluid padding-bottom-4px ms-2 me-2">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mt-2 mb-2 fs-4">
            <li className="breadcrumb-item">
              <Link to={domainDetails ? getPortalUrlFromId(domainDetails.portalId) : "/"}>
                <span className="material-icons text-muted" style={{ paddingTop: "6px" }}>
                  home
                </span>
              </Link>
            </li>
            {props.navPath &&
              props.navPath.map((path) => (
                <Fragment key={createKey()}>
                  <li className="breadcrumb-item">
                    <Link key={createKey()} to={path.urlparent} className="text-decoration-none text-muted">
                      {path.parent}
                    </Link>
                  </li>
                </Fragment>
              ))}
            <li className="breadcrumb-item active text-muted" aria-current="location">
              {props.title}
            </li>
          </ol>
        </nav>
        <div className="mt-1">
          <div>
            {props.help &&
              (createEnabled() || retrieveEnabled() || updateEnabled()) &&
              props.help.map((helpLine) => (
                <Fragment key={createKey()}>
                  <h6 className="font-weight-400 text-muted mb-0">{helpLine}</h6>
                </Fragment>
              ))}
          </div>
          {/* <div className="d-flex flex-column flex-wrap w-100 mt-3 mb-2"> */}
          <div className="d-flex justify-content-between flex-wrap mt-3 mb-2 align-middle">
            <div className="container-search d-flex flex-row flex-wrap col-10 col-sm-8">
              <>
                <SmartInput
                  key="txtSearch"
                  id="txtSearch"
                  value={searchInfo}
                  onKeyDown={handleEnterKeyDown}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control form-input middlePosition search-width inputBox search-icon-svg mb-0"
                  placeholder={props.searchLabel}
                // required
                />
                <button
                  type="button"
                  className="btn btn-link rounded-circle p-0"
                  style={{ transform: "translateX(-36px) translateY(4px)" }}
                  onClick={handleSearchIcon}
                >
                  <span className="material-icons-outlined text-muted">search</span>
                </button>
              </>
              {retrieveEnabled() && (
                <>
                  <div className="form-check form-switch mt-2 ms-3">
                    <input
                      key="showAllVersions"
                      className="form-check-input"
                      type="checkbox"
                      id="showAllVersions"
                      onClick={ShowAllVersionsChanged}
                    />
                    <label key={createKey()} className="form-check-label" htmlFor="showAllVersions">
                      Exibir revisões anteriores
                    </label>
                  </div>
                  <div className="form-check form-switch mt-2 ms-3">
                    <input
                      key="showActivesInactives"
                      className="form-check-input"
                      type="checkbox"
                      id="showActivesInactives"
                      onClick={ShowActivesInactivesChanged}
                    />
                    <label key={createKey()} className="form-check-label" htmlFor="showActivesInactives">
                      Exibir não publicados
                    </label>
                  </div>
                </>
              )}
            </div>
            <div>
              {createEnabled() && (
                <Link
                  key="btnCreate-sm"
                  id="btnCreate-sm"
                  to={props.urlCRUD}
                  className="CreateButtom btn btn-primary btn-sm material-fab-small-button"
                  title={props.tituloCRUD}
                  role="button"
                >
                  <div className="d-flex vertical-middle">
                    <span className="material-icons">add</span>
                  </div>
                </Link>
              )}
              {createEnabled() && (
                <Link
                  key="btnCreate-top"
                  id="btnCreate-top"
                  to={props.urlCRUD}
                  className="CreateButtom btn btn-primary btn-sm material-add-button"
                  title={props.addLabel}
                  role="button"
                >
                  <div className="d-flex vertical-middle">
                    <span role="button" className="material-icons">
                      add
                    </span>
                    <label key={createKey()} role="button" className="material-add-label">
                      {props.addLabel}
                    </label>
                  </div>
                </Link>
              )}
            </div>
          </div>
          <div className="container ps-0 pe-0" style={{ marginLeft: "-21px", minWidth: "calc(100% + 25px)" }}>
            <Form>
              <div>
                {error && (
                  <Alert key={createKey()} variant="danger" className="ms-4 me-1">
                    {error}
                  </Alert>
                )}
              </div>
              {dados.documents && (
                <>
                  <div className="card mt-3 mb-2 border-0">
                    {dados.documents.map((doc, indxDoc) => (
                      <Fragment key={`${doc.id}_${indxDoc}`}>
                        {/* Mobile Componente */}
                        <div className="card col-12 col-sm-12 col-md-12 col-lg-12 mb-0 border-0 d-flex flex-row align-items-start sw sw-sm hd-md hd-lg hd-xl hd-xxl">
                          <div className="d-flex flex-column pb-2 w-100">
                            <div className="">
                              <a key={createKey()} href={`${props.urlView}?id=${doc.id}`} className="text-decoration-none">
                                <img
                                  key={createKey()}
                                  src={doc.data().src}
                                  className="img-noticia-destaque"
                                  alt=""
                                  style={{
                                    minHeight: "100%",
                                    maxHeight: "100%",
                                    objectFit: doc.data().objectFit,
                                  }}
                                />
                              </a>
                            </div>
                            <div className="d-flex flex-row justify-content-between flex-wrap">
                              <div
                                className="btn btn-link text-primary text-decoration-none fs-5 text-start mt-2 ps-3 pt-1 pe-3 col-10"
                              // style={{ textTransform: "capitalize" }}
                              >
                                <a
                                  key={createKey()}
                                  href={`${props.urlView}?id=${doc.id}`}
                                  className="text-decoration-none"
                                // style={{ textTransform: "capitalize" }}
                                >
                                  {doc.data().title}
                                </a>
                              </div>

                              <div className="btn-group border-none pb-0 pb-sm-1 pt-2 col">
                                <div className="d-flex flex-nowrap" style={{ height: "46px" }}>
                                  <button
                                    key={createKey()}
                                    type="button"
                                    className="btn btn-sm ms-2 p-0 mb-0 mt-1 rounded-circle"
                                    // style={{ height: "42px" }}
                                    id="dropdownMenuReference"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    data-bs-reference="parent"
                                  >
                                    <span className="material-icons-outlined text-secondary rounded-circle btn-hover-lightgray p-2">
                                      more_vert
                                    </span>
                                    <span className="visually-hidden">Toggle Dropdown</span>
                                  </button>
                                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuReference">
                                    {(retrieveEnabled() || updateEnabled() || stateEnabled()) && (
                                      <li>
                                        <a
                                          key={createKey()}
                                          href={`${props.urlCRUD}?id=${doc.id}`}
                                          className="dropdown-item"
                                          rel="noreferrer"
                                          target="_blank"
                                        >
                                          Editar esta notícia
                                        </a>
                                      </li>
                                    )}
                                    {(deleteEnabled() || stateEnabled()) && (
                                      <li>
                                        <button
                                          key={createKey()}
                                          className="dropdown-item"
                                          type="button"
                                          data-id={doc.id}
                                          onClick={handleExcluir}
                                          data-bs-toggle="modal"
                                          data-bs-target="#myDeleteDialog"
                                        >
                                          Excluir esta notícia
                                        </button>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>

                            {doc.data().sumario && doc.data().sumario.length > 0 ? (
                              <div
                                className="card-text text-muted fs-6 ms-3 me-4 ps-4 pe-0"
                                style={{ fontSize: "x-small!important", textAlign: "justify" }}
                                dangerouslySetInnerHTML={{ __html: `${doc.data().sumario.replace("<br/></p>", "</p>")}` }}
                              ></div>
                            ) : (
                              doc.data().descricao && (
                                <div
                                  className="card-text text-muted fs-6 ms-3 me-4 ps-4 pe-0 preview-text"
                                  style={{ fontSize: "x-small!important", textAlign: "justify" }}
                                  dangerouslySetInnerHTML={{ __html: `${doc.data().descricao.replace("<br/></p>", "</p>")}` }}
                                ></div>
                              )
                            )}
                            <div className="ps-3 pe-3" style={{ maxWidth: "18%", minWidth: "18%" }}>
                              <hr />
                            </div>
                            <div className="d-flex flex-row ps-3 pe-3">
                              <div className="text-muted mb-0 pb-0" style={{ fontSize: "0.6875em" }}>
                                {doc
                                  .data()
                                  .category.split(",")
                                  .map((categoryItem) => (
                                    <span
                                      key={createKey()}
                                      className="badge rounded-pill bg-primary me-2"
                                      style={{ padding: "4px 8px 4px 8px" }}
                                    >
                                      {categoryItem.trim().toUpperCase()}
                                    </span>
                                  ))}
                              </div>
                              <div
                                className="text-muted pt-0 ps-3 pb-2"
                                style={{ minWidth: "125px", fontSize: "0.6875em", marginTop: "1px" }}
                              >
                                {compactDate(doc.data().dtNoticia.toDate())}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Desktop Componente */}
                        <div className="container ps-0 pe-0">
                          <div className="card col-12 col-sm-12 col-md-12 col-lg-12 mb-0 border-0 d-flex flex-row align-items-start hd hd-sm sw-md sw-lg sw-xl sw-xxl">
                            <div
                              className="text-muted pt-2 ps-3 pb-2"
                              style={{ minWidth: "125px", fontSize: "0.6875em", marginTop: "1px" }}
                            >
                              {/* {console.log("doc.data().dtNoticia.toDate()=", doc.data().dtNoticia.toDate())} */}
                              {compactDate(doc.data().dtNoticia.toDate())}
                            </div>
                            <div
                              className="d-flex flex-column pb-2 pe-4"
                              style={{
                                minWidth: "calc(100% - 125px - (100% * 3 / 12))",
                                maxWidth: "calc(100% - 125px - (100% * 3 / 12))",
                              }}
                            >
                              <div
                                className="btn btn-link text-primary text-decoration-none fs-4 text-start ps-0 pt-0 pe-0"
                              // style={{ textTransform: "capitalize" }}
                              >
                                <a
                                  key={createKey()}
                                  href={`${props.urlView}?id=${doc.id}`}
                                  className="text-decoration-none"
                                // style={{ textTransform: "capitalize" }}
                                >
                                  {doc.data().title}
                                </a>
                              </div>
                              {doc.data().sumario && doc.data().sumario.length > 0 ? (
                                <div
                                  className="card-text text-muted fs-6 ps-4 pe-4"
                                  style={{ fontSize: "x-small!important", textAlign: "justify" }}
                                  dangerouslySetInnerHTML={{ __html: `${doc.data().sumario.replace("<br/></p>", "</p>")}` }}
                                ></div>
                              ) : (
                                doc.data().descricao && (
                                  <div
                                    className="card-text text-muted fs-6 ps-4 pe-4 preview-text"
                                    style={{ fontSize: "x-small!important", textAlign: "justify" }}
                                    dangerouslySetInnerHTML={{ __html: `${doc.data().descricao.replace("<br/></p>", "</p>")}` }}
                                  ></div>
                                )
                              )}
                              <div style={{ maxWidth: "18%", minWidth: "18%" }}>
                                <hr />
                              </div>
                              <div className="d-flex flex-row justify-content-between flex-wrap">
                                <div className="text-muted mb-0 pb-0" style={{ fontSize: "0.6875em" }}>
                                  {doc
                                    .data()
                                    .category.split(",")
                                    .map((category) => (
                                      <span
                                        key={createKey()}
                                        className="badge rounded-pill bg-primary me-2"
                                        style={{ padding: "4px 8px 4px 8px" }}
                                      >
                                        {category.trim().toUpperCase()}
                                      </span>
                                    ))}
                                </div>

                                <div
                                  className="d-flex flex-row flex-nowrap align-middle pt-0 pb-0 mb-0"
                                  style={{ minWidth: "100px", marginTop: "-8px" }}
                                >
                                  <div className="d-flex justify-content-end form-check form-switch ms-0 align-middle mt-0 mb-0 p-0">
                                    {(updateEnabled() || stateEnabled()) && (
                                      <a
                                        key={createKey()}
                                        href={`${props.urlCRUD}?id=${doc.id}`}
                                        className="btn btn-sm btn-link rounded-circle btn-hover-lightgray"
                                        rel="noreferrer"
                                        target="_blank"
                                      >
                                        <span
                                          className="material-icons-outlined text-primary"
                                          title="Editar esta notícia"
                                          data-indxdoc={indxDoc}
                                        >
                                          edit
                                        </span>
                                      </a>
                                    )}
                                    {(deleteEnabled() || stateEnabled()) && (
                                      <>
                                        <button
                                          key={createKey()}
                                          href="#"
                                          className="btn btn-sm btn-link rounded-circle btn-hover-lightgray"
                                          type="button"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          onClick={handleExcluir}
                                          data-bs-toggle="modal"
                                          data-bs-target="#myDeleteDialog"
                                          title="Excluir esta notícia"
                                        >
                                          <span className="material-icons-outlined text-danger" data-id={doc.id}>
                                            delete
                                          </span>
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-3" style={{ maxWidth: "280px", minWidth: "180px" }}>
                              <a key={createKey()} href={`${props.urlView}?id=${doc.id}`} className="text-decoration-none">
                                <img
                                  key={createKey()}
                                  src={doc.data().src}
                                  className="img-noticia-destaque-rounded"
                                  alt=""
                                  style={{
                                    minHeight: "100%",
                                    maxHeight: "100%",
                                    objectFit: doc.data().objectFit,
                                  }}
                                />
                              </a>
                            </div>
                          </div>
                          <div className="col-12">
                            <hr />
                          </div>
                        </div>
                      </Fragment>
                    ))}
                    {/* <hr className="sw sw-sm hd-md hd-lg hd-xl hd-xxl" /> */}
                  </div>
                  <div className="d-flex justify-content-between flex-wrap align-middle pt-1">
                    <div className="justify-center-sm">
                      <span className="text-muted ">Mostrar:&nbsp;</span>
                      <select
                        key={createKey()}
                        className="text-muted"
                        value={pageSize}
                        onChange={(e) => {
                          e.preventDefault();
                          setPageSize(Number(e.target.value));
                        }}
                      >
                        {[10, 25, 50, 100].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            {pageSize}&nbsp;Itens por página
                          </option>
                        ))}
                      </select>
                      &nbsp; &nbsp;
                    </div>
                    <div className="navigation-mt-sm justify-center-sm">
                      <nav key={createKey()} aria-label="Page navigation">
                        <ul className="pagination pagination-sm justify-content-center">
                          {/* <li className="page-item pt-1"></li>
                          <li className="page-item">
                            <button
                              key={createKey()}
                              className="btn page-link"
                              type="button"
                              disabled={!prevButtonEnabled}
                              // aria-disabled="true"
                              onClick={(e) => {
                                e.preventDefault();
                                retrivePrevData();
                              }}
                            >
                              Anterior
                            </button>
                          </li>
                          <li className="page-item pt-1">
                            <span className="text-muted">
                              &nbsp;&nbsp;{currentPage}&nbsp;de&nbsp;
                              {quantidadePaginas()}&nbsp;&nbsp;
                            </span>
                          </li>
                          <li className="page-item">
                            <button
                              key={createKey()}
                              className="btn page-link"
                              type="button"
                              disabled={!nextButtonEnabled}
                              onClick={(e) => {
                                e.preventDefault();
                                retriveNextData();
                              }}
                            >
                              Próximo
                            </button>
                          </li> */}
                          <li className="page-item">
                            <button
                              className="btn page-link"
                              type="button"
                              // disabled={!nextButtonEnabled}
                              onClick={(e) => {
                                e.preventDefault();
                                retriveMoreData();
                              }}
                            >
                              <div className="d-flex vertical-middle">
                                <label role="button" className="material-add-label me-2" style={{ paddingTop: "2px" }}>
                                  Mostrar Mais
                                </label>
                                <span role="button" className="material-icons">
                                  chevron_right
                                </span>
                              </div>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div className="search-space">
                      {createEnabled() && (
                        <Link
                          key={createKey()}
                          id="btnCreate-bottom"
                          to={props.urlCRUD}
                          className="btn btn-primary btn-sm ms-2 material-add-button"
                          title={props.tituloCRUD}
                          role="button"
                        >
                          <div className="d-flex vertical-middle">
                            <span role="button" className="material-icons">
                              add
                            </span>
                            <label key={createKey()} role="button" className="material-add-label">
                              {props.addLabel}
                            </label>
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
      {createEnabled() && (
        <Link
          key={createKey()}
          id="btnCreate-floatting"
          to="/addUsersGroup"
          className="fab floating-add-button"
          title="Adicionar grupo de usuários"
          role="button"
        >
          <span role="button" className="material-icons">
            add
          </span>
        </Link>
      )}
      <DeleteRegisterDialog key="myDeleteDialog" id="myDeleteDialog" deleteData={handleDialogExcluir} />
      <BusySpinner key={createKey()} />
    </>
  );
}
