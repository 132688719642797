import React from "react";
//import { showSpinner, hideSpinner } from "../components/BusySpinner";

// export const deleteData = () => {
//   showSpinner();
//   //console.log("saveData");
//   this.props.deleteData();
//   hideSpinner();
// };

const DeleteRegisterDialog = (props) => {
  return (
    <>
      <div id={props.id ? props.id : "myConfirmationModal"} className="modal fade bs-modal-darness">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-light">
              <div className="d-flex justify-content-start align-items-center">
                <img className="logo-confirmation-modal" src="/assets/images/CBTKD_simbolo_cor.svg" alt="" style={{ minHeight: "46px", maxHeight: "46px" }} />
                <h4 className="modal-title">Você tem certeza?</h4>
              </div>
              <button type="button" className="close close-icon-flat" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{props.customMessage ? props.customMessage : "Você realmente deseja excluir este registro? Esta operação não pode ser desfeita."}</p>
            </div>
            <div className="modal-footer justify-content-center">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={props.deleteData}>
                {props.customButtonLabel ? props.customButtonLabel : "Excluir"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteRegisterDialog;
