import { db } from "../utils/firestore";
import {
  collection,
  addDoc,
} from "firebase/firestore";
import { Timestamp } from "firebase/firestore";

class ConsoleLog {
  constructor() {
    // this.consoleLog = null;
    // this.initializeConnection();
  }

  async log(message, message2, message3, message4, message5, message6, message7, message8, message9, message10) {
    let completeMessage = message;
    if (message2) completeMessage += " " + message2;
    if (message3) completeMessage += " " + message3;
    if (message4) completeMessage += " " + message4;
    if (message5) completeMessage += " " + message5;
    if (message6) completeMessage += " " + message6;
    if (message7) completeMessage += " " + message7;
    if (message8) completeMessage += " " + message8;
    if (message9) completeMessage += " " + message9;
    if (message10) completeMessage += " " + message10;
    // //console.log(completeMessage);    
    
    const data = {
      timestamp: Timestamp.now(),
      message: completeMessage,
    };
    addDoc(collection(db, "consolelog"), data)
    .then((docRef) => {
      //console.log("Erro:", data);
    })
    .catch((error) => {
        //console.log("Erro:", error);
        // res.send;
      });
  }
}
export default new ConsoleLog();