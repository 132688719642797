import { Timestamp } from "firebase/firestore";
// import moment from "moment-timezone";
import format from "date-fns/format";

export function DateTimeZoneFix(dateString) {
  // console.log("DateTimeZoneFix => dateString =", dateString);
  // console.log(typeof dateString);
  // return Timestamp.fromDate(moment.tz(new Date(dateString), "Horário Padrão de Brasília"));
  // return Timestamp.fromDate(new Date(new Date().setHours(4, 0, 0, 0)));
  // //console.log("DateTimeZoneFix(dateString)=", typeOf(dateString));
  if (dateString === "") {
    return "";
  } else if (typeof dateString === "object") {
    return Timestamp.fromDate(dateString);
  } else if (typeof dateString === "number") {
    return Timestamp.fromDate(new Date(dateString));
  } else if (typeof dateString === "string") {
    if (dateString.indexOf(" 00:00:00 GMT-0300") > 0 || dateString.lenght > 10) {
      return Timestamp.fromDate(new Date(dateString));
    } else {
      return Timestamp.fromDate(new Date(dateString + " 00:00:00 GMT-0300"));
    }
  }
  // return Timestamp.fromDate(
  //   new Date(dateString.indexOf(" 00:00:00 GMT-0300") > 0 || dateString.lenght > 10 ? dateString : dateString + " 00:00:00 GMT-0300")
  // );
}

export function DateTimeZoneFixToday() {
  // return Timestamp.fromDate(moment.tz(new Date(dateString), "Horário Padrão de Brasília"));
  // return Timestamp.fromDate(new Date(new Date().setHours(4, 0, 0, 0)));

  // //console.log("new Date().toDate()=", new Date());
  // //console.log("format(new Date().toDate(), 'yyyy-MM-dd')=", format(new Date(), "yyyy-MM-dd"));

  // //console.log("DateTimeZoneFixToday()=", format(new Date(), "yyyy-MM-dd") + " 00:00:00 GMT-0300");
  // return Timestamp.fromDate(new Date(format(new Date().toDate(), "yyyy-MM-dd") + " 00:00:00 GMT-0300"));

  return DateTimeZoneFix(format(new Date(), "yyyy-MM-dd"));
}
