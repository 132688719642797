import React, { Fragment, useEffect, useState } from "react";
import PrintCertificadoComponent from "../../components/certificados/printCertificadoComponent";
import contasService from "../../services/contas.service";
// import ServicesService from "../../services/Services.Service";
// import GenericVinculadoService from "../../services/GenericVinculado.service";
// import ServicesSearchComponent from "../../components/services/ServicesSearchComponent";
// import GenericService from "../../services/Generic.service";
// import { showSpinner, hideSpinner } from "../../components/BusySpinner";
import { useAuth } from "../../contexts/AuthContext";
import GenericService from "../../services/Generic.service";

export default function CertificatePage() {
  const { currentUser } = useAuth();
  const queryParams = new URLSearchParams(window.location.search);
  const [doc, setDoc] = useState(undefined);
  const [certificadoUrl, setCertificadoUrl] = useState(undefined);

  useEffect(async () => {
    if (currentUser !== undefined && currentUser !== null && currentUser !== "" && Object.keys(currentUser).length > 0) {
      if (queryParams.get("id") !== undefined && queryParams.get("id") !== null && queryParams.get("id") !== "") {
        await contasService
          .getOne(queryParams.get("id"))
          .then(async (conta) => {
            if (conta) {
              console.log("conta", conta);
              setDoc(conta);
            }
          }).catch((error) => {
            console.log("error", error);
          });
      }
    } else if (certificadoUrl === undefined) {
      // console.log("currentUser=", currentUser);
      // console.log("queryParams.get('id')=", queryParams.get("id"));
      if (queryParams.get("id") !== undefined && queryParams.get("id") !== null && queryParams.get("id") !== "") {
        await GenericService
          .getOne("certificadovalidate", queryParams.get("id"))
          .then(async (certificadoData) => {
            console.log("certificadoData:", certificadoData);
            if (certificadoData) {
              console.log("certificadoData.url:", certificadoData.url);
              setCertificadoUrl(certificadoData.url);
            }
          }).catch((error) => {
            console.log("error", error);
          });
      }
    }
  }, [currentUser]);


  const scale = () => {
    let returnScale = (window.innerWidth / 1394) * (0.9); //90% para deixar uma borda ao redor do certificdo
    if (returnScale >= 1) returnScale = 1;
    return returnScale;
  };

  const heightOriginal = () => 984;
  const widthOriginal = () => 1394;

  // console.log
  if (certificadoUrl !== undefined) {
    return (
      <div className="col-12 mt-2">
        <img
          src={certificadoUrl ? certificadoUrl : ""}
          // className="img-thumbnail rounded"
          className="w-100"
          alt=""
        // style={{ maxWidth: "615px", maxHeight: "120px", minWidth: "615px", minHeight: "120px" }}
        />
      </div>
    );
  } else if (currentUser === undefined) {
    return (
      <>
        <div className="col-12 p-4">
          <div className="alert alert-info d-flex align-items-center" role="alert">
            <span className="material-icons-outlined me-2">info</span>
            <div style={{ fontSize: "0.85rem" }}>Precisa realizar o login para poder ver este certificado</div>
          </div>
        </div>
      </>
    );
  } else if (doc === undefined && certificadoUrl === undefined) {
    return (<>Carregando...</>);
  } else {
    return (
      <div className="d-flex w-100" style={{
        minHeight: `${heightOriginal() * scale()}px`,
        maxHeight: `${heightOriginal() * scale()}px`,

      }}>
        <div className="m-4 border border-3 w-100" id="report" style={{
          scale: `${scale().toString()}`,
          aspectRatio: "1394 / 984",
          transform: `translate(-${(1 - scale() * 0.9) * widthOriginal() / 2}px, -${(1 - scale() * 0.9) * heightOriginal() / 2}px)`,
        }}>
          <PrintCertificadoComponent
            doc={doc && doc}
          />
        </div>
      </div>
    );
  }
}
