export const GetPortal = () => {
  let location = window.location.href;
  let portalsCollection = [
    { id: "2", urlportal: "sie" },
    { id: "3", urlportal: "transparencia" },
    { id: "4", urlportal: "institucional" },
    { id: "5", urlportal: "cbtkdeduca" },
    { id: "6", urlportal: "helpdesk" },
    { id: "1", urlportal: "" },
  ];
  let portal = portalsCollection.find((portalItem) => location.includes(portalItem.urlportal));
  return portal;
};
