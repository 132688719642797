import React, { Fragment, useState, useEffect } from "react";
import PrintCredentialComponent from "../../../components/eventos/credentials/printCredentialComponent";
import { showSpinner } from "../../../components/BusySpinner";
// import PrintCertificadoComponent from "../../components/certification/printCertificadoComponent";
// import ServicesService from "../../services/Services.Service";
// import GenericVinculadoService from "../../services/GenericVinculado.service";
// import ServicesSearchComponent from "../../components/services/ServicesSearchComponent";
import GenericService from "../../../services/Generic.service";
import { getModalidadeByCodename } from "../../../components/eventos/utils/EventosCodenameUtils";
// import { showSpinner, hideSpinner } from "../../components/BusySpinner";
// import { useAuth } from "../../contexts/AuthContext";

export default function CredenciaisPage() {
  // const CREATERULE = 700;
  // const RETRIEVERULE = 701;
  // //const UPDATERULE = 702;
  // //const DELETERULE = 703;
  // const STATERULE = 704;
  // const { domainDetails } = useAuth();
  const queryParams = new URLSearchParams(window.location.search);
  const [error, setError] = useState("");
  const scale = () => {
    let returnScale = (window.innerWidth / 1394) * (0.9); //90% para deixar uma borda ao redor do certificdo
    if (returnScale >= 1) returnScale = 1;
    return returnScale;
  };
  const heightOriginal = () => 1241;
  const widthOriginal = () => 1714;
  const scaledHeight = () => window.innerHeight;
  const scaledWidth = () => window.innerWidth;
  // const topMargin = () => ((-1) * scale() * (heightOriginal() - scaledHeight()));
  // const topMargin = () => scaledHeight - scale() * heightOriginal() / 2;
  // const topMargin = () => (-120 + (-1) * (heightOriginal() - scaledHeight()));
  // const leftMargin = () => ((-1) * (widthOriginal() - scaledWidth()));
  const topMargin = () => ((1 - scale() * 0.9) * heightOriginal() / 2);
  const leftMargin = () => ((1 - scale() * 0.9) * widthOriginal() / 2);
  // const leftMargin = () => ((-1) * (window.innerWidth / 1392) * (window.innerWidth / 2)).toString();
  // console.log("window.innerWidth=", window.innerWidth);
  console.log("scale=", scale());
  // console.log("1392 * scale=", window.innerWidth * (window.innerWidth / 1392));
  console.log("heightOriginal=", heightOriginal());
  // console.log("widthOriginal=", widthOriginal());
  console.log("scaledHeight=", scaledHeight());
  // console.log("scaledWidth=", scaledWidth());
  // console.log("window.innerHeight=", window.innerHeight);
  console.log("topMargin=", topMargin());
  console.log("leftMargin=", leftMargin());
  // console.log("%=", (1 - scale() * 0.9) * 100);

  useEffect(() => {
    loadInscritos();
    // window.addEventListener("resize", () => {
    //   console.log("window.innerWidth=", window.innerWidth);
    //   console.log("scale=", scale());
    //   console.log("heightOriginal=", heightOriginal());
    //   console.log("scaledHeight=", scaledHeight());
    //   console.log("topMargin=", topMargin());
    //   console.log("leftMargin=", leftMargin());
    // });
  }, []);

  const [lstInscritos, setLstInscritos] = useState([]);
  const loadInscritos = async () => {
    //load todas as inscrições do evento e criar lista de inscritos, por modalidade e ordenados por name em ordem crescente
    // //console.log("loadInscritos called");
    let indxDuplaColorida = 0;
    let indxDuplaPreta = 0;
    let indxEquipeColorida = 0;
    let indxEquipePreta = 0;
    let listaInscritos = [];
    // await GenericService.getDoubleFilteredCollection("inscricoes", "idEvento", "==", queryParams.get("id"), "metadados.active", "==", true, null, "asc")
    await GenericService.getFilteredCollection("inscricoes", "dadosEvento.idEvento", "==", queryParams.get("id"), null, "asc")
      .then((inscricoes) => {
        // //console.log("inscricoes=", inscricoes);
        let inscritos = [];
        // querySnapshot.forEach((doc) => {

        inscricoes.forEach(async (inscricao) => {
          let inscricaoId = inscricao.id;
          inscricao = inscricao.data();
          // //console.log("inscricao.inscritos=", inscricao.inscritos);
          // inscricao.inscritos.forEach((modalidade) => {
          let modalidadesKeys = Object.keys(inscricao.inscritos);
          // //console.log("modalidadesKeys=", modalidadesKeys);
          modalidadesKeys.forEach(async (modalidadeKey) => {
            let modalidade = getModalidadeByCodename(modalidadeKey);
            inscricao.inscritos[modalidadeKey].forEach(async (inscricaoNaModalidade) => {
              if (inscricaoNaModalidade.hasOwnProperty("financeiro")) {
                let situacao = "";
                let valorInscricao = 0;
                inscricaoNaModalidade.financeiro.forEach(async (itemFinanceiro) => {
                  if (itemFinanceiro.descricao.includes("Taxa da inscrição")) {
                    situacao = itemFinanceiro.situacao;
                    if (situacao === "Aguardando o pagamento do boleto") {
                      situacao = "Aguardando o pagamento da taxa de inscrição";
                    }
                    else if (situacao === "Aguardando a geração do boleto") {
                      situacao = "Aguardando a geração da taxa de inscrição";
                    }
                    // //console.log("itemFinanceiro:", itemFinanceiro);
                    valorInscricao = itemFinanceiro.valor;
                  }
                });
                // //console.log("inscricaoNaModalidade=", inscricaoNaModalidade);
                let role = "Athlete";
                if (modalidadeKey === "tecnicos") {
                  role = "Coach";
                }
                else if (modalidadeKey === "comissaoTecnica") {
                  role = "Staff";
                }
                let nameArray = inscricaoNaModalidade.name.split(" ");
                let Weightclass = inscricaoNaModalidade.peso ? inscricaoNaModalidade.peso : "";
                Weightclass = Weightclass.replace("Até ", "-");
                Weightclass = Weightclass.replace("Acima de ", "+");
                Weightclass = Weightclass.replace("Kg", "kg");
                if (!Weightclass.includes("kg") && !Weightclass.includes(" à ")) {
                  Weightclass = Weightclass + "kg";
                }
                Weightclass = Weightclass.replace("--", "-");
                // let belt = inscricaoNaModalidade.MaxGraduacao.substring(inscricaoNaModalidade.MaxGraduacao.length - 3).toUpperCase();
                let belt = inscricaoNaModalidade.MaxGraduacao.toUpperCase();
                let Category = (inscricaoNaModalidade.sexo === "Masculino" ? "M" : "F") + Weightclass;
                let Division = inscricaoNaModalidade.classeSelecionada;
                if (role === "Coach" || role === "Staff") {
                  Weightclass = "";
                  Category = "";
                  Division = "";
                }

                // if (inscricaoNaModalidade.peso !== undefined && inscricaoNaModalidade.peso.indexOf("Até") !== -1 && inscricaoNaModalidade.peso.indexOf("Kg") === -1 && inscricaoNaModalidade.peso.indexOf("kg") === -1) {
                //   inscricaoNaModalidade.peso += "Kg";
                // }
                if (inscricaoNaModalidade.peso !== undefined && inscricaoNaModalidade.peso.indexOf("Até") !== -1 && inscricaoNaModalidade.peso.indexOf("Kg") === -1 && inscricaoNaModalidade.peso.indexOf("kg") === -1) {
                  if (inscricaoNaModalidade.classeSelecionada !== undefined
                    && inscricaoNaModalidade.classeSelecionada !== ""
                    && !(inscricaoNaModalidade.classeSelecionada.indexOf("Cadete") > -1
                      && inscricaoNaModalidade.maxGraduacao >= 11)
                  ) {
                    if (inscricaoNaModalidade.peso.indexOf("Até 1") === -1) {
                      inscricaoNaModalidade.peso += "Kg";
                    }
                  }
                }
                if (inscritos.filter((inscrito) => (inscrito.name === inscricaoNaModalidade.name && inscrito.modalidade === modalidade)).length === 0) {
                  inscritos.push({
                    modalidade: getModalidadeByCodename(modalidadeKey),
                    name: inscricaoNaModalidade.name,
                    FirstName: nameArray[0],
                    Lastname: nameArray[nameArray.length - 1],
                    MaxGraduacao: inscricaoNaModalidade.MaxGraduacao,
                    classe: inscricaoNaModalidade.classeSelecionada,
                    peso: inscricaoNaModalidade.peso,
                    sexo: inscricaoNaModalidade.sexo,
                    uf: inscricaoNaModalidade.logradouroUF,
                    federacao: inscricaoNaModalidade.federacao,
                    situacao: situacao,
                    idInscricao: inscricaoId,
                    logradouroUF: inscricaoNaModalidade.logradouroUF,
                    country: inscricaoNaModalidade.country,
                    birthdate: inscricaoNaModalidade.birthdate,
                    Belt: belt,
                    role: role,
                    Weightclass: Weightclass,
                    Category: Category,
                    Division: Division,
                    Foto: "",
                    valorInscricao: valorInscricao,
                    indxThisDuplaEquipe: "",
                    // cpf: await PraticantesService.getCPFFromPraticanteId(inscricaoNaModalidade.uid),
                  });
                }
              }
              else {
                let indxThisDuplaEquipe = 0;
                //console.log("getModalidadeByCodename(modalidadeKey)=", getModalidadeByCodename(modalidadeKey));
                if (getModalidadeByCodename(modalidadeKey).includes("Dupla")) {
                  if (getModalidadeByCodename(modalidadeKey).includes("Coloridas")) {
                    indxDuplaColorida += 1;
                    indxThisDuplaEquipe = indxDuplaColorida;
                  } else {
                    indxDuplaPreta += 1;
                    indxThisDuplaEquipe = indxDuplaPreta;
                  }
                  //console.log("indxThisDuplaEquipe=", indxThisDuplaEquipe);
                }
                else if (getModalidadeByCodename(modalidadeKey).includes("Equipe")) {
                  if (getModalidadeByCodename(modalidadeKey).includes("Coloridas")) {
                    indxEquipeColorida += 1;
                    indxThisDuplaEquipe = indxEquipeColorida;
                  } else {
                    indxEquipePreta += 1;
                    indxThisDuplaEquipe = indxEquipePreta;
                  }
                  //console.log("indxThisDuplaEquipe=", indxThisDuplaEquipe);
                }
                let atletasKeys = Object.keys(inscricaoNaModalidade);
                atletasKeys.forEach(async (atletaKey) => {
                  if (inscricaoNaModalidade[atletaKey].hasOwnProperty("financeiro")) {
                    // //console.log("inscricaoNaModalidade[atletaKey]: ", inscricaoNaModalidade[atletaKey]);
                    let situacao = "";
                    let valorInscricao = 0;
                    inscricaoNaModalidade[atletaKey].financeiro.forEach(async (itemFinanceiro) => {
                      if (itemFinanceiro.descricao.includes("Taxa da inscrição")) {
                        situacao = itemFinanceiro.situacao;
                        if (situacao === "Aguardando o pagamento do boleto") {
                          situacao = "Aguardando o pagamento da taxa de inscrição";
                        }
                        else if (situacao === "Aguardando a geração do boleto") {
                          situacao = "Aguardando a geração da taxa de inscrição";
                        }
                        // //console.log("itemFinanceiro:", itemFinanceiro);
                        valorInscricao = itemFinanceiro.valor;
                      }
                    });
                    let role = "Athlete";
                    if (modalidadeKey === "tecnicos") {
                      role = "Coach";
                    }
                    else if (modalidadeKey === "comissaoTecnica") {
                      role = "Staff";
                    }
                    let nameArray = inscricaoNaModalidade[atletaKey].name.split(" ");
                    let Weightclass = inscricaoNaModalidade[atletaKey].peso ? inscricaoNaModalidade[atletaKey].peso : "";
                    Weightclass = Weightclass.replace("Até ", "-");
                    Weightclass = Weightclass.replace("Acima de ", "+");
                    Weightclass = Weightclass.replace("Kg", "kg");
                    Weightclass = Weightclass.replace("--", "-");
                    if (!Weightclass.includes("kg") && !Weightclass.includes(" à "))
                      Weightclass = Weightclass + "kg";
                    // let belt = inscricaoNaModalidade[atletaKey].MaxGraduacao.substring(inscricaoNaModalidade[atletaKey].MaxGraduacao.length - 3).toUpperCase();
                    let belt = inscricaoNaModalidade[atletaKey].MaxGraduacao.toUpperCase();
                    let Category = (inscricaoNaModalidade[atletaKey].sexo === "Masculino" ? "M" : "F") + Weightclass;
                    let Division = inscricaoNaModalidade[atletaKey].classeSelecionada;
                    if (role === "Coach" || role === "Staff") {
                      Weightclass = "";
                      Category = "";
                      Division = "";
                    }

                    // if (inscricaoNaModalidade[atletaKey].peso.indexOf("Até") !== -1 && inscricaoNaModalidade[atletaKey].peso.indexOf("Kg") === -1 && inscricaoNaModalidade[atletaKey].peso.indexOf("kg") === -1) {
                    //   inscricaoNaModalidade[atletaKey].peso += "Kg";
                    // }
                    if (inscricaoNaModalidade[atletaKey].peso !== undefined && inscricaoNaModalidade[atletaKey].peso.indexOf("Até") !== -1 && inscricaoNaModalidade[atletaKey].peso.indexOf("Kg") === -1 && inscricaoNaModalidade[atletaKey].peso.indexOf("kg") === -1) {
                      if (inscricaoNaModalidade[atletaKey].classeSelecionada !== undefined
                        && inscricaoNaModalidade[atletaKey].classeSelecionada !== ""
                        && !(inscricaoNaModalidade[atletaKey].classeSelecionada.indexOf("Cadete") > -1
                          && inscricaoNaModalidade[atletaKey].maxGraduacao >= 11)
                      ) {
                        if (inscricaoNaModalidade[atletaKey].peso.indexOf("Até 1") === -1) {
                          inscricaoNaModalidade[atletaKey].peso += "Kg";
                        }
                      }
                    }

                    if (inscritos.filter((inscrito) => (inscrito.name === inscricaoNaModalidade[atletaKey].name && inscrito.modalidade === modalidade)).length === 0) {
                      inscritos.push({
                        modalidade: getModalidadeByCodename(modalidadeKey),
                        name: inscricaoNaModalidade[atletaKey].name,
                        FirstName: nameArray[0],
                        Lastname: nameArray[nameArray.length - 1],
                        MaxGraduacao: inscricaoNaModalidade[atletaKey].MaxGraduacao,
                        classe: inscricaoNaModalidade[atletaKey].classeSelecionada,
                        peso: inscricaoNaModalidade[atletaKey].peso,
                        sexo: inscricaoNaModalidade[atletaKey].sexo,
                        uf: inscricaoNaModalidade[atletaKey].logradouroUF,
                        federacao: inscricaoNaModalidade[atletaKey].federacao,
                        situacao: situacao,
                        idInscricao: inscricaoId,
                        logradouroUF: inscricaoNaModalidade[atletaKey].logradouroUF,
                        country: inscricaoNaModalidade[atletaKey].country,
                        birthdate: inscricaoNaModalidade[atletaKey].birthdate,
                        Belt: belt,
                        role: role,
                        Weightclass: Weightclass,
                        Category: Category,
                        Division: Division,
                        Foto: "",
                        valorInscricao: valorInscricao,
                        indxThisDuplaEquipe: indxThisDuplaEquipe,
                        // cpf: await PraticantesService.getCPFFromPraticanteId(inscricaoNaModalidade[atletaKey].uid),
                      });
                    }
                  }
                });
              }
            });
          });
          // });
          // if (updateInscroesData) {
          //   //console.log("contas => delete => inscricao=", inscricao);
          //   // await updateDoc(doc(db, "inscricoes", inscricaoId), inscricao);
          // }
          // updateDoc(doc(db, "inscricoes", inscricaoId), inscricao);
        });

        //order inscritos by modalidade, sexo, classe, name, peso
        inscritos.sort((a, b) => {
          // if (a.modalidade < b.modalidade) return -1;
          // if (a.modalidade > b.modalidade) return 1;
          // if (a.sexo < b.sexo) return -1;
          // if (a.sexo > b.sexo) return 1;
          // if (a.classe < b.classe) return -1;
          // if (a.classe > b.classe) return 1;
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          // if (a.peso < b.peso) return -1;
          // if (a.peso > b.peso) return 1;
          return 0;
        });

        //console.log("inscritos=", inscritos);
        setLstInscritos(inscritos);
        // }


      }).catch((err) => {
        console.log(err);
        setError(err.message);
      });

    // //console.log("inscritos=", inscritos);
    // setData((prevState) => ({
    //   ...prevState,
    //   inscritos: inscritos,
    // }));
  };



  return (
    <>
      {lstInscritos.length > 0 && lstInscritos.map((inscrito, indxInscrito) => (
        <div className="d-flex w-100" style={{
          // scale: `${scale().toString()}`
          minHeight: `${heightOriginal() * scale()}px`,
          maxHeight: `${heightOriginal() * scale()}px`,
          // minWidth: `${scaledWidth() * scale()}px`,
          // maxWidth: `${scaledWidth() * scale()}px`,

        }}>
          <div className="m-4 border border-3 w-100" id="report" style={{
            scale: `${scale().toString()}`,
            // scale: "(100%, 100%)",
            aspectRatio: "1714 / 1241",
            // scale: `${scale().toString()}`, aspectRatio: "1394 / 984",
            transform: `translate(-${(1 - scale() * 0.9) * widthOriginal() / 2}px, -${(1 - scale() * 0.9) * heightOriginal() / 2}px)`,
            // minHeight: `${heightOriginal() * scale()}px`,
            // maxHeight: `${heightOriginal() * scale()}px`,
            // minWidth: `${widthOriginal() * scale()}px`,
            // maxWidth: `${widthOriginal() * scale()}px`,
          }}>
            <PrintCredentialComponent />
          </div>
        </div>
      ))}
    </>
  );
}
