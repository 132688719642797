// import { Timestamp } from "@google-cloud/firestore";
import { Timestamp } from "firebase/firestore";

export const PraticanteModelo = (currentUser) => {
  return {
    matriculaCBTKD: "",
    dadosPessoais: {
      name: "",
      mothersName: "",
      fathersName: "",
      cpf: "",
      cnpj: "",
      birthdate: "",
      estadoCivil: "",
      nacionalidade: "",
      apelido: "",
      urlImagemID: "",
      sexo: "Masculino",
    },
    metadados: {
      ownerid: currentUser ? currentUser.uid ? currentUser.uid : undefined : undefined,
      owner: currentUser ? currentUser.displayName ? currentUser.displayName : undefined: undefined,
      creation: Timestamp.fromDate(new Date()),
      date: Timestamp.fromDate(new Date()),
      successorId: "",
      version: 0,
      active: false,
      federativeactive: false,
    },
    // usersgroupsowned: undefined,
    dadosContato:{
      email: "",
      celular: "",
      telefone: "",
      telefoneComercial: "",
    },
    dadosEndereco: {
      cep: "",
      logradouro: "",
      logradouroNumero: "",
      logradouroComplemento: "",
      logradouroBairro: "",
      logradouroMunicipio: "",
      logradouroUF: "",
    },
    dadosFiliacao: {
      federacao: "",
      federacaoID: "",
      matriculaFederacao: "",
      vinculadoAEntidade: "",
      vinculadoAEntidadeID: "",
    },
    // dadosTaekwondo: undefined,
    // dadosColaborador: undefined,
    dadosBiograficos: {
      biografia: "",
    },
    historicoCertificacoes: [],
    documentos: [],
    anuidades: [],
  };
};
