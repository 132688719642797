import { hasAllArrayElements } from "./arrayUtils";

export function keepOrderIfNotDuplicated(conditions) {
  for (let indx = 0; indx < conditions.length - 1; indx++) {
    if (indx < conditions.length - 1) {
      if (conditions[indx].type === conditions[conditions.length - 1].type) {
        if (hasAllArrayElements(conditions[indx]._field.segments, conditions[conditions.length - 1]._field.segments)) {
          conditions.splice(conditions.length - 1, 1);
        }
      }
    }
  }
  return conditions;
}
