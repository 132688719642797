import { db } from "../utils/firestore";
import {
  doc,
  getDoc,
  collection,
  addDoc,
  getDocs,
  updateDoc,
  // deleteDoc,
  query,
  orderBy,
  where,
  startAfter,
  endBefore,
  limit,
  setDoc,
  limitToLast,
  getCountFromServer,
} from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import {
  // todayZeroHour,
  timestampTodayZeroHour,
  timestampTodayLastTime,
  timestampZeroHourFromAddDays,
  timestampLastTimeFromAddDays,
  timeStampFromAddDays,
} from "../utils/dateForFirebaseUtils";
// import { DonutLarge } from "@material-ui/icons";
import { generateTrigam } from "../utils/trigamUtils";
import { removeAccents } from "../utils/removeAccents";
import axios from "axios";
import ConsoleLog from "./consoleLog.service";
import { CBTKD_ID } from "../constantes/EntidadesConstantes";
import PraticantesService from "./Praticantes.Service";
import GenericService from "./Generic.service";
import { DateTimeZoneFixToday } from "../utils/DateTimeZoneFix";
// require("dotenv").config();

//const dbCollection = db.collection("/contasgroups");

class contasService {

  BloquearVerificaPagamento = true;

  getOne = async (id) => {
    try {
      if (id) {
        const noteSnapshot = await getDoc(doc(db, "contas", id));
        if (noteSnapshot.exists()) {
          // console.log("noteSnapshot=", noteSnapshot);
          // console.log("retData._document=", noteSnapshot._document);
          // console.log("noteSnapshot._document.createTime.timestamp.toDate()=", noteSnapshot._document.createTime.timestamp.toDate());
          // console.log("noteSnapshot._document.readTime.timestamp.toDate()=", noteSnapshot._document.readTime.timestamp.toDate());
          // console.log("noteSnapshot._document.version.timestamp.toDate()=", noteSnapshot._document.version.timestamp.toDate());

          return noteSnapshot.data();
        } else {
          throw new Error("Registro não encontrado!");
        }
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  };
  //usage: getNote(id);

  getAll = async (tipoConta, beneficiarioId) => {
    const querySnapshot = await getDocs(
      query(
        collection(db, "contas"),
        where("metadados.active", "==", true),
        where("tipo", "==", tipoConta),
        where("vinculo.beneficiarioId", "==", beneficiarioId),
        orderBy("vencimento", "asc")
      )
    );
    //console.log("querySnapshot=", querySnapshot);
    //console.log("querySnapshot.docs=", querySnapshot.docs);
    return querySnapshot.docs;
  };

  getCollectionSizeByIdEntidade = async (tipoConta, beneficiarioId, queryTarget, queryFilter, show_1_Active_2_Inactive_3_Both) => {
    if (show_1_Active_2_Inactive_3_Both === undefined) show_1_Active_2_Inactive_3_Both = 1;
    let conditions = await this.getConditionsFromQueryFilterByIdEntidade(beneficiarioId, queryTarget, queryFilter);
    if (show_1_Active_2_Inactive_3_Both !== 3) conditions.push(where("metadados.active", "==", show_1_Active_2_Inactive_3_Both === 1));
    conditions.push(where("tipo", "==", tipoConta));
    //console.log("conditions=", conditions);
    let queryObj = query(
      collection(db, "contas"),
      ...conditions
    );
    const snapshot = await getCountFromServer(queryObj);
    //console.log("count: ", snapshot.data().count);
    return snapshot.data().count;
  };

  getCollectionSize = async (tipoConta, beneficiarioId, queryTarget, queryFilter, show_1_Active_2_Inactive_3_Both) => {
    if (show_1_Active_2_Inactive_3_Both === undefined) show_1_Active_2_Inactive_3_Both = 1;
    let conditions = await this.getConditionsFromQueryFilter(beneficiarioId, queryTarget, queryFilter);
    if (show_1_Active_2_Inactive_3_Both !== 3) conditions.push(where("metadados.active", "==", show_1_Active_2_Inactive_3_Both === 1));
    conditions.push(where("tipo", "==", tipoConta));
    let queryObj = query(
      collection(db, "contas"),
      ...conditions
    );
    const snapshot = await getCountFromServer(queryObj);
    //console.log("count: ", snapshot.data().count);
    return snapshot.data().count;
  };

  getFilteredCollection = async (tipoConta, beneficiarioId, filterTarget, operation, filterValue) => {
    // //console.log(
    //   "tipoConta=",
    //   tipoConta,
    //   ", beneficiarioId=",
    //   beneficiarioId,
    //   ", filterTarget=",
    //   filterTarget,
    //   ", operation=",
    //   operation,
    //   ", filterValue=",
    //   filterValue
    // );
    /*
     * ToDo: Avaliar se esta funcionalidade é necessária
     */
    const querySnapshot = await getDocs(
      query(
        collection(db, "contas"),
        where("metadados.active", "==", true),
        where("tipo", "==", tipoConta),
        where(filterTarget, operation, filterValue),
        orderBy("vencimento", "asc")
      )
    );
    return [querySnapshot.docs, querySnapshot.docs.length];
  };

  getConditionsFromQueryFilter = async (beneficiarioId, queryTarget, queryFilter) => {
    // //console.log("queryFilter=", queryFilter);
    const conditions = [];
    conditions.push(where("vinculo.beneficiarioId", "==", beneficiarioId));
    if (queryFilter !== undefined) {
      if (queryFilter.situacaoSearch === "Em aberto") {
        conditions.push(where("pagamento.pago", "==", false));
        // conditions.push(where("pagamento.dataPagamento", "==", ""));
      } else if (queryFilter.situacaoSearch === "Pago") {
        conditions.push(where("pagamento.pago", "==", true));
        // conditions.push(where("pagamento.dataPagamento", "!=", ""));
      }

      if (queryFilter.tipoSearch !== "Todas") {
        conditions.push(where("tipoCobranca", "==", queryFilter.tipoSearch));
      }
      if (queryFilter.referenciaDataPeriodoSearch) {
        let firstDay = 0;
        let lastDay = 0;
        switch (queryFilter.dataPeriodoSearch) {
          case "Hoje":
            conditions.push(this.periodoCondition(queryFilter, ">=", timestampTodayZeroHour()));
            conditions.push(this.periodoCondition(queryFilter, "<=", timestampTodayLastTime()));
            break;
          case "Ontem":
            conditions.push(this.periodoCondition(queryFilter, ">=", timestampZeroHourFromAddDays(new Date(), -1)));
            conditions.push(this.periodoCondition(queryFilter, "<=", timestampLastTimeFromAddDays(new Date(), -1)));
            break;
          case "Últimos 7 dias":
            conditions.push(this.periodoCondition(queryFilter, ">=", timestampZeroHourFromAddDays(new Date(), -7)));
            conditions.push(this.periodoCondition(queryFilter, "<=", timestampTodayLastTime()));
            break;
          case "Último mês":
            firstDay = new Date(new Date().getYear() + 1900, new Date().getMonth() - 1, 1, 0, 0, 0, 0);
            lastDay = new Date(new Date().getYear() + 1900, new Date().getMonth(), 0, 20, 59, 59, 999);
            //console.log("firstDay=", firstDay);
            //console.log("lastDay=", lastDay);
            conditions.push(this.periodoCondition(queryFilter, ">=", Timestamp.fromDate(firstDay)));
            conditions.push(this.periodoCondition(queryFilter, "<=", Timestamp.fromDate(lastDay)));
            break;
          case "Este mês":
            firstDay = new Date(new Date().getYear() + 1900, new Date().getMonth(), 1, 0, 0, 0, 0);
            lastDay = new Date(new Date().getYear() + 1900, new Date().getMonth() + 1, 0, 20, 59, 59, 999);
            //console.log("firstDay=", firstDay);
            //console.log("lastDay=", lastDay);
            conditions.push(this.periodoCondition(queryFilter, ">=", Timestamp.fromDate(firstDay)));
            conditions.push(this.periodoCondition(queryFilter, "<=", Timestamp.fromDate(lastDay)));
            break;
          case "Este ano":
            firstDay = new Date(new Date().getYear() + 1900, 0, 1, 0, 0, 0, 0);
            lastDay = new Date(new Date().getYear() + 1900, 11, 31, 20, 59, 59, 999);
            //console.log("firstDay=", firstDay);
            //console.log("lastDay=", lastDay);
            conditions.push(this.periodoCondition(queryFilter, ">=", Timestamp.fromDate(firstDay)));
            conditions.push(this.periodoCondition(queryFilter, "<=", Timestamp.fromDate(lastDay)));
            break;
          case "Escolher período":
            //console.log("queryFilter=", queryFilter);
            firstDay = new Date(queryFilter.dataPeriodoInicioSearch + " 00:00:00");
            lastDay = new Date(queryFilter.dataPeriodoFimSearch + " 20:59:59.999");
            //console.log("firstDay=", firstDay);
            //console.log("lastDay=", lastDay);
            conditions.push(this.periodoCondition(queryFilter, ">=", Timestamp.fromDate(firstDay)));
            conditions.push(this.periodoCondition(queryFilter, "<=", Timestamp.fromDate(lastDay)));
            break;
          default:
            break;
        }
      }
      //console.log("queryTarget=", queryTarget, "queryFilter=", queryFilter);
      if (queryFilter.searchInfo.length > 0) {
        if (["%", "*", "?"].includes(queryFilter.searchInfo.substring(0, 1))) {
          queryFilter.searchInfo = queryFilter.searchInfo.substring(1, queryFilter.length);
          queryFilter.searchInfo = queryFilter.searchInfo.trim().toLowerCase().replaceAll("  ", " ");
          conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryFilter.searchInfo).slice(0, 10)]));
          // if (queryFilter.searchInfo) {
          //   if (queryFilter.searchInfo.length > 0) conditions.push(orderBy(queryTarget, "asc"));
          // }
        } else {
          queryTarget = queryTarget.replace("trigam", "name");
          conditions.push(where(queryTarget, ">=", queryFilter.searchInfo));
          conditions.push(where(queryTarget, "<=", queryFilter.searchInfo + "\uf8ff"));
        }
      }

      // if (queryFilter.searchInfo !== "") {
      //   // conditions.push(where(queryTarget, ">=", queryFilter.searchInfo));
      //   // conditions.push(where(queryTarget, "<=", queryFilter.searchInfo + "\uf8ff"));
      //   conditions.push(where(queryTarget, "array-contains-any", [...generateTrigam(queryFilter.searchInfo).slice(0, 10)]));
      // }

      if (queryFilter.referenciaDataPeriodoSearch === "Pagamento") {
        conditions.push(orderBy("pagamento.dataPagamento", "asc"));
      }

      if (queryFilter.referenciaDataPeriodoSearch === "Documento") {
        conditions.push(orderBy("dataDocumento", "asc"));
      }

      if (queryFilter.searchInfo !== "") {
        conditions.push(orderBy(queryTarget, "asc"));
      }
    } else {
      //console.log("queryFilter=", queryFilter);
    }
    // //console.log("conditions=", conditions);
    return conditions;
  };

  getConditionsFromQueryFilterByIdEntidade = async (beneficiarioId, queryTarget, queryFilter) => {
    //console.log("getConditionsFromQueryFilterByIdEntidade(", beneficiarioId, ", ", queryTarget, ", ", queryFilter, ")");
    // //console.log("queryFilter=", queryFilter);
    const conditions = [];
    conditions.push(where("vinculo.beneficiarioId", "==", beneficiarioId));
    if (queryFilter !== undefined) {
      if (queryFilter.situacaoSearch === "Em aberto") {
        conditions.push(where("pagamento.pago", "==", false));
        // conditions.push(where("pagamento.dataPagamento", "==", ""));
      } else if (queryFilter.situacaoSearch === "Pago") {
        conditions.push(where("pagamento.pago", "==", true));
        // conditions.push(where("pagamento.dataPagamento", "!=", ""));
      }

      if (queryFilter.tipoSearch !== "Todas") {
        conditions.push(where("tipoCobranca", "==", queryFilter.tipoSearch));
      }
      if (queryFilter.referenciaDataPeriodoSearch) {
        let firstDay = 0;
        let lastDay = 0;
        switch (queryFilter.dataPeriodoSearch) {
          case "Hoje":
            conditions.push(this.periodoCondition(queryFilter, ">=", timestampTodayZeroHour()));
            conditions.push(this.periodoCondition(queryFilter, "<=", timestampTodayLastTime()));
            break;
          case "Ontem":
            conditions.push(this.periodoCondition(queryFilter, ">=", timestampZeroHourFromAddDays(new Date(), -1)));
            conditions.push(this.periodoCondition(queryFilter, "<=", timestampLastTimeFromAddDays(new Date(), -1)));
            break;
          case "Últimos 7 dias":
            conditions.push(this.periodoCondition(queryFilter, ">=", timestampZeroHourFromAddDays(new Date(), -7)));
            conditions.push(this.periodoCondition(queryFilter, "<=", timestampTodayLastTime()));
            break;
          case "Último mês":
            firstDay = new Date(new Date().getYear() + 1900, new Date().getMonth() - 1, 1, 0, 0, 0, 0);
            lastDay = new Date(new Date().getYear() + 1900, new Date().getMonth(), 0, 20, 59, 59, 999);
            //console.log("firstDay=", firstDay);
            //console.log("lastDay=", lastDay);
            conditions.push(this.periodoCondition(queryFilter, ">=", Timestamp.fromDate(firstDay)));
            conditions.push(this.periodoCondition(queryFilter, "<=", Timestamp.fromDate(lastDay)));
            break;
          case "Este mês":
            firstDay = new Date(new Date().getYear() + 1900, new Date().getMonth(), 1, 0, 0, 0, 0);
            lastDay = new Date(new Date().getYear() + 1900, new Date().getMonth() + 1, 0, 20, 59, 59, 999);
            //console.log("firstDay=", firstDay);
            //console.log("lastDay=", lastDay);
            conditions.push(this.periodoCondition(queryFilter, ">=", Timestamp.fromDate(firstDay)));
            conditions.push(this.periodoCondition(queryFilter, "<=", Timestamp.fromDate(lastDay)));
            break;
          case "Este ano":
            firstDay = new Date(new Date().getYear() + 1900, 0, 1, 0, 0, 0, 0);
            lastDay = new Date(new Date().getYear() + 1900, 11, 31, 20, 59, 59, 999);
            //console.log("firstDay=", firstDay);
            //console.log("lastDay=", lastDay);
            conditions.push(this.periodoCondition(queryFilter, ">=", Timestamp.fromDate(firstDay)));
            conditions.push(this.periodoCondition(queryFilter, "<=", Timestamp.fromDate(lastDay)));
            break;
          case "Escolher período":
            //console.log("queryFilter=", queryFilter);
            firstDay = new Date(queryFilter.dataPeriodoInicioSearch + " 00:00:00");
            lastDay = new Date(queryFilter.dataPeriodoFimSearch + " 20:59:59.999");
            //console.log("firstDay=", firstDay);
            //console.log("lastDay=", lastDay);
            conditions.push(this.periodoCondition(queryFilter, ">=", Timestamp.fromDate(firstDay)));
            conditions.push(this.periodoCondition(queryFilter, "<=", Timestamp.fromDate(lastDay)));
            break;
          default:
            break;
        }
      }
      //console.log("queryTarget=", queryTarget, "queryFilter=", queryFilter);
      if (queryFilter.searchInfo.length > 0 && queryFilter.searchIdEntidade.length > 0) {
        conditions.push(where("vinculo.idEntidade", "==", queryFilter.searchIdEntidade));
      }

      if (queryFilter.categoriaSearch !== undefined && queryFilter.categoriaSearch.length > 0) {
        conditions.push(where("servicoCategory", "==", queryFilter.categoriaSearch));
      }

      if (queryFilter.vincularAoEventoSearch !== undefined && queryFilter.vincularAoEventoSearch.length > 0) {
        conditions.push(where("vinculo.vincularAoEvento", "==", queryFilter.vincularAoEventoSearch));
      }



      if (queryFilter.referenciaDataPeriodoSearch === "Pagamento") {
        conditions.push(orderBy("pagamento.dataPagamento", "asc"));
      }

      if (queryFilter.referenciaDataPeriodoSearch === "Documento") {
        conditions.push(orderBy("dataDocumento", "asc"));
      }
    } else {
      //console.log("queryFilter=", queryFilter);
    }
    // //console.log("conditions=", conditions);
    return conditions;
  };


  // getCollectionFromByIdEntidadeAndBeneficiarioId_Simple = async (idEntidade, beneficiarioId) => {
  //     const conditions = [];
  //     conditions.push(where("vinculo.beneficiarioId", "==", beneficiarioId));
  //     conditions.push(where("vinculo.idEntidade", "==", idEntidade));
  //     // conditions.push(where("servicoCategory","in", servicosCategorys));
  //     // conditions.push(where("bolix.chargeId", "==", 662545954));
  //     // conditions.push(orderBy("metadados.creation", "desc"));
  //     conditions.push(orderBy("vencimento", "desc"));
  //     // conditions.push(limit(10));
  //     let queryObj = query(collection(db, "contas"), ...conditions);
  //     let querySnapshot = await getDocs(queryObj);
  //   let collectionSize = querySnapshot.length;
  //   return [querySnapshot.docs, collectionSize];
  // };

  getCollectionFromByIdEntidadeAndBeneficiarioId = async (idEntidade, beneficiarioId, servicosCategorys, activeState, updateFromEFI) => {
    // console.log("getCollectionFromByIdEntidadeAndBeneficiarioId => idEntidade=", idEntidade, "beneficiarioId=", beneficiarioId, "servicosCategorys=", servicosCategorys, "activeState=", activeState, "updateFromEFI=", updateFromEFI);
    // let anuidadePaga = false;
    // console.log("servicosCategorys=", servicosCategorys);
    let compositequerySnapshot = [];
    // for (let i = 0; i < Math.ceil(servicosCategorys.length / 10); i++) {
    //   let servicosCategorysSlice = servicosCategorys.slice(i * 10, (i + 1) * 10);
    const conditions = [];
    if (beneficiarioId !== CBTKD_ID) {
      conditions.push(where("vinculo.beneficiarioId", "in", [CBTKD_ID, beneficiarioId]));
    } else {
      // conditions.push(where("vinculo.beneficiarioId", "==", beneficiarioId));
      conditions.push(where("vinculo.beneficiarioId", "==", CBTKD_ID));
    }
    conditions.push(where("vinculo.idEntidade", "==", idEntidade));
    // conditions.push(where("servicoCategory", "in", servicosCategorysSlice));
    conditions.push(where("metadados.active", "==", activeState));
    // conditions.push(where("bolix.chargeId", "==", 662545954));
    conditions.push(orderBy("metadados.creation", "desc"));
    // conditions.push(orderBy("vencimento", "desc"));
    // conditions.push(limit(10));
    // console.log("conditions=", conditions);
    // let queryObj = query(collection(db, "contas"), ...conditions);
    let querySnapshot = await getDocs(query(collection(db, "contas"), ...conditions));
    // console.log("querySnapshot.docs.length=", querySnapshot.docs.length);
    // compositequerySnapshot = [...compositequerySnapshot, ...querySnapshot.docs];
    // compositequerySnapshot = [...querySnapshot.docs];
    // }
    // compositequerySnapshot = compositequerySnapshot.filter((a) => servicosCategorys.includes(a.data().servicoCategory));
    // console.log("querySnapshot.docs.length=", querySnapshot.docs.length, "servicosCategorys.length=", servicosCategorys.length);
    // if (querySnapshot.docs.length > 0 && servicosCategorys.length > 0) {
    compositequerySnapshot = querySnapshot.docs.filter((a) => servicosCategorys.includes(a.data().servicoCategory));
    // compositequerySnapshot = querySnapshot.docs;
    // } else {
    //   compositequerySnapshot = querySnapshot.docs;
    // }

    // compositequerySnapshot.sort((a, b) => {
    //   if (a.data().metadados.creation < b.data().metadados.creation) {
    //     return 1;
    //   }
    //   if (a.data().metadados.creation > b.data().metadados.creation) {
    //     return -1;
    //   }
    //   return 0;
    // });
    // console.log("updateFromEFI=", updateFromEFI);
    if (updateFromEFI) {
      // console.log("Vai fazer o updateFromEFI");
      return await this.getUpdatedStatusFromCobranca(compositequerySnapshot)
        .then((response) => {
          // console.log("Fez o updateFromEFI => response=", response);
          return [response, response.length];
        }).catch((error) => {
          // return [[], 0];
          throw error;
        });
    } else {
      // console.log("Não vai fazer o updateFromEFI");
      return [compositequerySnapshot, compositequerySnapshot.length];
    }
  };

  timestampFromString(dateString) {
    // console.log("timestampFromString => dateString:", dateString);
    //'2024-01-06 04:35:03'
    let date = [...dateString.split(" ")[0].split("-"), ...dateString.split(" ")[1].split(":")];
    return Timestamp.fromDate(new Date(date[0], date[1] - 1, date[2], date[3], date[4], date[5]));
  }


  getUpdatedStatusFromCobranca = async (compositequerySnapshot) => {
    try {
      let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/gnet/detailcharge";
      if (process.env.REACT_APP_ENV === "development") console.log("urlService=", urlService);
      // console.log("urlService=", urlService);
      return Promise.all(await compositequerySnapshot.map(async (doc) => {
        if (!doc.data().pagamento.pago && !this.BloquearVerificaPagamento) {
          if (doc.data().bolixInfo !== undefined && doc.data().bolixInfo.chargeId !== undefined) {
            await axios.post(urlService, { id: doc.data().bolixInfo.chargeId })
              .then((response) => {
                // console.log(response);
                if (response.data.code === 200) {
                  if (response.data.data.status === "paid") {
                    doc.data().pagamento.pago = true;
                    doc.data().pagamento.dataPagamento = this.timestampFromString(response.data.data.history[response.data.data.history.length - 1].created_at);
                    doc.data().pagamento.totalPago = response.data.data.amount;
                    doc.data().pagamento.totalRecebido = response.data.data.amount;
                    this.updatePropertyOnVinculo(doc.id, "pagamento", doc.data().pagamento);
                    if (doc.data().servicoCategory === "Anuidade" || doc.data().servicoCategory === "Anuidade colorida") {
                      PraticantesService.updateActiveState(doc.data().vinculo.idEntidade, true);
                    }
                  }
                  else if (response.data.data.status === "canceled") {
                    doc.data().metadados.active = false;
                    this.updatePropertyOnVinculo(doc.id, "metadatos.active", false);
                  }
                }
              }, (error) => {
                console.log(error);
              });
          }
        }
        return doc;
      }));

    } catch (error) {
      console.log("getUpdatedStatusFromCobranca error=", error);
      throw error;
    }
  }

  getUpdatedStatusFromCobrancaByContaId = async (contaId, forceUpdate) => {
    // console.log("getUpdatedStatusFromCobrancaByContaId => contaId=", contaId, "forceUpdate=", forceUpdate);
    await this.getOne(contaId)
      .then(async (doc) => {
        // console.log("doc=", doc);
        if (!doc.pagamento.pago && (!this.BloquearVerificaPagamento || forceUpdate)) {
          await this.getUpdatedStatusFromCobrancaChargeId(doc.bolixInfo.chargeId, forceUpdate);
        }
      }).catch((error) => {
        console.log(error);
        throw error;
      });
  }

  getUpdatedStatusFromCobrancaChargeId = async (chargeId, forceUpdate) => {
    try {
      if (forceUpdate === undefined) forceUpdate = false;
      let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/gnet/detailcharge";
      if (process.env.REACT_APP_ENV === "development" && false) console.log("urlService=", urlService);
      //   if (!doc.data().pagamento.pago) {
      if (chargeId !== undefined && (!this.BloquearVerificaPagamento || forceUpdate)) {
        await axios.post(urlService, { id: chargeId })
          .then((response) => {
            console.log(response);
            if (response.data.code === 200) {
              if (response.data.data.status === "paid") {
                getDocs(
                  query(
                    collection(db, "contas"),
                    where("metadados.active", "==", true),
                    where("bolixInfo.chargeId", "==", chargeId)
                  )
                ).then((querySnapshot) => {
                  console.log("querySnapshot=", querySnapshot);
                  let doc = querySnapshot.docs[0];
                  doc.data().pagamento.pago = true;
                  doc.data().pagamento.dataPagamento = this.timestampFromString(response.data.data.history[response.data.data.history.length - 1].created_at);
                  doc.data().pagamento.totalPago = response.data.data.amount;
                  doc.data().pagamento.totalRecebido = response.data.data.amount;
                  this.updatePropertyOnVinculo(doc.id, "pagamento", doc.data().pagamento);
                  if (doc.data().servicoCategory === "Anuidade" || doc.data().servicoCategory === "Anuidade colorida") {
                    PraticantesService.updateActiveState(doc.data().vinculo.idEntidade, true);
                  }
                }).catch((error) => {
                  console.log(error);
                  throw error;
                });
              }
              else if (response.data.data.status === "canceled") {
                doc.data().metadados.active = false;
                this.updatePropertyOnVinculo(doc.id, "metadatos.active", false);
              }
            }
          }, (error) => {
            console.log(error);
            throw error;
          });
      }
      // }
      // return doc;
      // }));

    } catch (error) {
      console.log("getUpdatedStatusFromCobranca error=", error);
      throw error;
    }
  }


  getCollectionSearchByIdEntidade = async (tipoConta, beneficiarioId, queryTarget, queryFilter, firstVisible, lastVisible, pageSize, show_1_Active_2_Inactive_3_Both) => {
    console.log(
      "getCollectionSearchByPrefix(tipoConta=",
      tipoConta,
      ", beneficiarioId=",
      beneficiarioId,
      ", queryTarget=",
      queryTarget,
      ", queryFilter=",
      queryFilter,
      ", firstVisible=",
      firstVisible,
      ", lastVisible=",
      lastVisible,
      ", pageSize=",
      pageSize,
      "show_1_Active_2_Inactive_3_Both=",
      show_1_Active_2_Inactive_3_Both,
      ")"
    );
    try {
      if (show_1_Active_2_Inactive_3_Both === undefined) show_1_Active_2_Inactive_3_Both = 1;
      let conditions = [];
      conditions = [...conditions, ...(await this.getConditionsFromQueryFilterByIdEntidade(beneficiarioId, queryTarget, queryFilter))];
      //console.log("conditions=", conditions);
      conditions.push(where("tipo", "==", tipoConta));
      if (show_1_Active_2_Inactive_3_Both !== 3) conditions.push(where("metadados.active", "==", show_1_Active_2_Inactive_3_Both === 1));
      if (pageSize !== null) {
        if (firstVisible !== undefined && firstVisible !== null && firstVisible !== "") conditions.push(limitToLast(pageSize));
        else conditions.push(limit(pageSize));
      }
      conditions.push(orderBy("vencimento", "asc"));
      if (firstVisible !== undefined && firstVisible !== null && firstVisible !== "") conditions.push(endBefore(firstVisible));
      if (lastVisible !== undefined && lastVisible !== null && lastVisible !== "") conditions.push(startAfter(lastVisible));
      console.log("conditions=", conditions);
      let queryObj = query(collection(db, "contas"), ...conditions);
      let querySnapshot = await getDocs(queryObj);
      let collectionSize = await this.getCollectionSizeByIdEntidade(tipoConta, beneficiarioId, queryTarget, queryFilter, show_1_Active_2_Inactive_3_Both);
      return [querySnapshot.docs, collectionSize];
    } catch (error) {
      //console.log("getCollectionSearchByPrefix error=", error);
      // return [[], 0];
      throw error;
    }
  };

  getCollectionSearchByPrefix = async (tipoConta, beneficiarioId, queryTarget, queryFilter, firstVisible, lastVisible, pageSize, show_1_Active_2_Inactive_3_Both) => {
    console.log(
      "getCollectionSearchByPrefix(tipoConta=",
      tipoConta,
      ", beneficiarioId=",
      beneficiarioId,
      ", queryTarget=",
      queryTarget,
      ", queryFilter=",
      queryFilter,
      ", firstVisible=",
      firstVisible,
      ", lastVisible=",
      lastVisible,
      ", pageSize=",
      pageSize,
      "show_1_Active_2_Inactive_3_Both=",
      show_1_Active_2_Inactive_3_Both,
      ")"
    );
    try {
      if (show_1_Active_2_Inactive_3_Both === undefined) show_1_Active_2_Inactive_3_Both = 1;
      let conditions = await this.getConditionsFromQueryFilter(beneficiarioId, queryTarget, queryFilter);
      conditions.push(where("tipo", "==", tipoConta));
      if (show_1_Active_2_Inactive_3_Both !== 3) conditions.push(where("metadados.active", "==", show_1_Active_2_Inactive_3_Both === 1));
      if (pageSize !== null) {
        if (firstVisible !== undefined && firstVisible !== null && firstVisible !== "") conditions.push(limitToLast(pageSize));
        else conditions.push(limit(pageSize));
      }
      conditions.push(orderBy("vencimento", "asc"));
      if (firstVisible !== undefined && firstVisible !== null && firstVisible !== "") conditions.push(endBefore(firstVisible));
      if (lastVisible !== undefined && lastVisible !== null && lastVisible !== "") conditions.push(startAfter(lastVisible));
      console.log("conditions=", conditions);
      let queryObj = query(collection(db, "contas"), ...conditions);
      let querySnapshot = await getDocs(queryObj);
      let collectionSize = await this.getCollectionSize(tipoConta, beneficiarioId, queryTarget, queryFilter, show_1_Active_2_Inactive_3_Both);
      return [querySnapshot.docs, collectionSize];
    } catch (error) {
      //console.log("getCollectionSearchByPrefix error=", error);
      // return [[], 0];
      throw error;
    }
  };

  periodoCondition = (queryFilter, operation, timestampValue) => {
    if (queryFilter.referenciaDataPeriodoSearch === "Documento") {
      return where("dataDocumento", operation, timestampValue);
    } else if (queryFilter.referenciaDataPeriodoSearch === "Pagamento") {
      return where("pagamento.dataPagamento", operation, timestampValue);
    } else if (queryFilter.referenciaDataPeriodoSearch === "Vencimento") {
      return where("vencimento", operation, timestampValue);
    }
  };

  getPrevPage = async (tipoConta, beneficiarioId, queryTarget, queryFilter, firstVisible, lastVisible, pageSize) => {
    // //console.log(
    //   "getPrevPage(",
    //   tipoConta,
    //   ", ",
    //   beneficiarioId,
    //   ", ",
    //   queryTarget,
    //   ", ",
    //   queryFilter,
    //   ", ",
    //   firstVisible,
    //   ", ",
    //   lastVisible,
    //   ", ",
    //   pageSize,
    //   ")"
    // );
    // let conditions = await this.getConditionsFromQueryFilter(beneficiarioId, queryTarget, queryFilter);
    // let queryObj = null;
    // if (conditions.length > 0) {
    //   queryObj = query(
    //     collection(db, "contas"),
    //     where("metadados.active", "==", true),
    //     where("tipo", "==", tipoConta),
    //     ...conditions,
    //     orderBy("vencimento", "asc"),
    //     limit(pageSize),
    //     endBefore(firstVisible)
    //   );
    // } else {
    //   queryObj = query(
    //     collection(db, "contas"),
    //     where("metadados.active", "==", true),
    //     where("tipo", "==", tipoConta),
    //     orderBy("vencimento", "asc"),
    //     limit(pageSize),
    //     endBefore(firstVisible)
    //   );
    // }
    // const querySnapshot = await getDocs(queryObj);
    // return querySnapshot.docs;
    return this.getCollectionSearchByPrefix(tipoConta, beneficiarioId, queryTarget, queryFilter, firstVisible, null, pageSize);
  };

  getNextPage = async (tipoConta, beneficiarioId, queryTarget, queryFilter, firstVisible, lastVisible, pageSize) => {
    // //console.log(
    //   "getNextPage(",
    //   tipoConta,
    //   ", ",
    //   beneficiarioId,
    //   ", ",
    //   queryTarget,
    //   ", ",
    //   queryFilter,
    //   ", ",
    //   firstVisible,
    //   ", ",
    //   lastVisible,
    //   ", ",
    //   pageSize,
    //   ")"
    // );
    // let conditions = await this.getConditionsFromQueryFilter(beneficiarioId, queryTarget, queryFilter);
    // let queryObj = null;
    // if (lastVisible !== null) {
    //   if (conditions.length > 0) {
    //     queryObj = query(
    //       collection(db, "contas"),
    //       where("metadados.active", "==", true),
    //       where("tipo", "==", tipoConta),
    //       ...conditions,
    //       orderBy("vencimento", "asc"),
    //       limit(pageSize),
    //       startAfter(lastVisible)
    //     );
    //   } else {
    //     queryObj = query(
    //       collection(db, "contas"),
    //       where("metadados.active", "==", true),
    //       where("tipo", "==", tipoConta),
    //       orderBy("vencimento", "asc"),
    //       limit(pageSize),
    //       startAfter(lastVisible)
    //     );
    //   }
    // } else {
    //   if (conditions.length > 0) {
    //     queryObj = query(
    //       collection(db, "contas"),
    //       where("metadados.active", "==", true),
    //       where("tipo", "==", tipoConta),
    //       ...conditions,
    //       orderBy("vencimento", "asc"),
    //       limit(pageSize)
    //     );
    //   } else {
    //     queryObj = query(
    //       collection(db, "contas"),
    //       where("metadados.active", "==", true),
    //       where("tipo", "==", tipoConta),
    //       orderBy("vencimento", "asc"),
    //       limit(pageSize)
    //     );
    //   }
    // }
    // const querySnapshot = await getDocs(queryObj);
    // return querySnapshot.docs;
    return this.getCollectionSearchByPrefix(tipoConta, beneficiarioId, queryTarget, queryFilter, null, lastVisible, pageSize);
  };

  // newID = async (tipoConta) => {
  //   const querySnapshot = await getDocs(query(collection(db, "contas"), orderBy("numero", "desc"), limit(1)));
  //   if (querySnapshot.docs.length > 0) {
  //     return Number(querySnapshot.docs[0].data().numero) + 1;
  //   } else {
  //     return 50000;
  //   }
  // };

  newID = async () => {
    const querySnapshot = await getCountFromServer(query(collection(db, "contas"), where("metadados.successorId", "==", "")));
    // //console.log("newID => querySnapshot.data().count=", querySnapshot.data().count);
    if (querySnapshot) {
      // console.log("newID => querySnapshot=", querySnapshot);
      // console.log("newID => querySnapshot.data().count=", querySnapshot.data().count);
      // console.log("newID => Number(querySnapshot.data().count) + 50000=", Number(querySnapshot.data().count) + 50000);

      return "SIE-" + Number((querySnapshot.data().count) + 50000);
    }
    //  else {
    //   return "SIE-" + 50000;
    // }
  };


  create = async (value, callbackFunction, doNotCreateCobranca, errorNotificationCallback) => {
    try {
      value = { ...value, vinculo: { ...value.vinculo, searchableName: removeAccents(value.vinculo.name.toLowerCase()), trigam: generateTrigam(value.vinculo.name) } };
      if (value.totalParcelas !== undefined && value.totalParcelas > 1) {
        value.observacao = "Parcela " + value.parcela + " de " + value.totalParcelas + ". " + value.observacao;
      }
      value.numero = await this.newID();
      if (process.env.REACT_APP_ENV === "development") console.log("value=", value);
      return await addDoc(collection(db, "contas"), value).then(async (docRef) => {
        if (value.pagamento.hasOwnProperty("pago")) {
          // console.log("doNotCreateCobranca=", doNotCreateCobranca);
          if (!value.pagamento.pago) {
            // console.log("doNotCreateCobranca=", doNotCreateCobranca);
            if (doNotCreateCobranca === undefined || doNotCreateCobranca === false) {
              await this.createCobranca(value, docRef, callbackFunction, errorNotificationCallback);
            } else {
              // console.log("doNotCreateCobranca=", doNotCreateCobranca);
              callbackFunction(docRef.id, undefined);
            }
          }
        }
        else {
          // console.log("doNotCreateCobranca=", doNotCreateCobranca);
          if (doNotCreateCobranca === undefined || doNotCreateCobranca === false) {
            await this.createCobranca(value, docRef, callbackFunction, errorNotificationCallback);
          } else {
            // console.log("doNotCreateCobranca=", doNotCreateCobranca);
            callbackFunction(docRef.id, undefined);
          }
        }
        if (process.env.REACT_APP_ENV === "development") console.log("docRef.id=", docRef.id);
        if (process.env.REACT_APP_ENV === "development") console.log("docRef=", docRef);
        return docRef;
      });
    } catch (error) {
      if (process.env.REACT_APP_ENV === "development") console.log("error=", error);
      throw error;
    }
  };

  createWithID = async (UID, value) => {
    try {
      value = { ...value, vinculo: { ...value.vinculo, searchableName: removeAccents(value.vinculo.name.toLowerCase()), trigam: generateTrigam(value.vinculo.name) } };
      value.numero = await this.newID();
      return setDoc(doc(db, "contas", UID.toString()), value);
    } catch (error) {
      throw error;
    }
  };

  createRecorrente = async (value, interval, endDate) => {
    try {
      value = { ...value, vinculo: { ...value.vinculo, searchableName: removeAccents(value.vinculo.name.toLowerCase()), trigam: generateTrigam(value.vinculo.name) } };
      value.observacao = "Parcela " + value.parcela + " de " + value.totalParcelas + ". " + value.observacao;
      while (value.vencimento <= endDate) {
        value.numero = this.newID();
        // await this.create(value);
        await addDoc(collection(db, "contas"), value);
        value.vencimento = timeStampFromAddDays(value.vencimento, Number(interval));
      }
      return true;
    } catch (error) {
      throw error;
    }
  };

  createParcelado = async (value, interval, quantity) => {
    try {
      value = { ...value, vinculo: { ...value.vinculo, searchableName: removeAccents(value.vinculo.name.toLowerCase()), trigam: generateTrigam(value.vinculo.name) } };
      let valorParcela = value.valor / quantity;
      let observacaoOriginal = value.observacao;
      value.totalParcelas = quantity;
      value.valor = valorParcela;
      // //console.log("value=", value, " interval=", interval, " quantity=", quantity);
      for (let index = 1; index <= quantity; index++) {
        value.parcela = index;
        value.observacao = "Parcela " + index + " de " + quantity + ". " + observacaoOriginal;
        // //console.log("value=", value, " parcela=", value.parcela);
        value.numero = this.newID();
        // await this.create(value);
        await addDoc(collection(db, "contas"), value);
        value.vencimento = timeStampFromAddDays(value.vencimento, Number(interval));
      }
      return true;
    } catch (error) {
      throw error;
    }
  };

  update = async (id, value) => {
    //console.log("contas => update => id = ", id, " value = ", value);
    try {
      value = { ...value, vinculo: { ...value.vinculo, searchableName: removeAccents(value.vinculo.name.toLowerCase()), trigam: generateTrigam(value.vinculo.name) } };
      this.getOne(id).then((retData) => {
        let oldVersion = {
          ...retData,
          metadados: {
            ...retData.metadados,
            active: false,
            successorId: id,
          },
        };
        addDoc(collection(db, "contas"), oldVersion).then((lastVersion) => {
          let ActualVersion = {
            ...value,
            metadados: {
              ...value.metadados,
              version: value.metadados.version + 1,
              successorId: "",
            },
          };
          updateDoc(doc(db, "contas", id), ActualVersion).then((retValue) => {
            // //console.log("ActualVersion=", ActualVersion);
            // //console.log("oldVersion=", oldVersion);
            if (ActualVersion.vinculo.idEntidade === "TRhRghIYvkTs5igtOt6YnoQGAvC3") {
              if (ActualVersion.pagamento.hasOwnProperty("pago")
                && ActualVersion.hasOwnProperty("bolixInfo")) {
                // //console.log("ActualVersion.pagamento.pago=", ActualVersion.pagamento.pago);
                if (ActualVersion.pagamento.pago) {
                  // //console.log("ActualVersion.bolixInfo=", ActualVersion.bolixInfo);
                  if (oldVersion.pagamento.hasOwnProperty("pago")) {
                    // //console.log("oldVersion.pagamento.pago=", oldVersion.pagamento.pago);
                    if (!oldVersion.pagamento.pago) {
                      // //console.log("settleTransaction");
                      let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/gnet/settletransaction";
                      axios.post(urlService, { id: ActualVersion.bolixInfo.chargeId })
                        .then((response) => {
                          //console.log(response);
                        }, (error) => {
                          //console.log(error);
                        });

                      //TODO: Verificar a eficiencia do código abaixo
                      // if (ActualVersion.vinculo.hasOwnProperty("idServicoCertificacao")) {
                      //   updateDoc(doc(db, "certificationservice", ActualVersion.vinculo.idServicoCertificacao), {["metadados.active"]: false}, { merge:true });
                      // }
                      // getDoc(doc(db, "praticantes", ActualVersion.vinculo.idEntidade))
                      // .then((praticante) => {
                      //   praticante = praticante.data();
                      //   praticante.histicoCertificacoes = praticante.histicoCertificacoes.map((certificacao) => {
                      //     if (certificacao.idServicoCertificacao === ActualVersion.vinculo.idServicoCertificacao 
                      //       && ActualVersion.taxa.idConta === id) {
                      //       certificacao.status = "Pago";
                      //       return certificacao;
                      //     }
                      //     else return certificacao;          
                      //   });
                      //   updateDoc(doc(db, "praticantes", ActualVersion.vinculo.idEntidade), praticante);
                      // }, (error) => {
                      //   //console.log(error);
                      // });
                    }
                  }
                }
              }
              if (ActualVersion.pagamento.hasOwnProperty("pago")) {
                if (ActualVersion.pagamento.pago) {
                  if (ActualVersion.servicoCategory !== undefined) {
                    if (ActualVersion.servicoCategory === "Anuidade" || ActualVersion.servicoCategory === "Anuidade colorida") {
                      PraticantesService.updateActiveState(ActualVersion.vinculo.idEntidade, true);
                    }
                  }
                }
              }
            }
            return retValue;
          });
        });
      });
    } catch (error) {
      throw error;
    }
  };

  // updatePropertyOnVinculo = async (id, propertyName, propertyValue) => {
  //   //console.log("contas => updateAddNewPropertyOnVinculo => id = ", id, " propertyName = ", propertyName, " propertyValue = ", propertyValue);
  //   try {
  //     this.getOne(id).then((retData) => {
  //       let newConta = {
  //         ...retData, 
  //         vinculo: {
  //           ...retData.vinculo, 
  //           [propertyName]: propertyValue
  //         }
  //       };
  //       //console.log("newConta=", newConta);
  //       this.update(id, newConta);
  //     }
  //     ).catch((error) => {
  //       throw error;
  //     });
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  updatePropertyOnVinculo = async (id, propertyName, propertyValue) => {
    updateDoc(doc(db, "contas", id), { [propertyName]: propertyValue }, { merge: true })
      .then((retValue) => {
        // //console.log("retValue=", retValue);
        return retValue;
      }).catch((error) => {
        throw error;
      });
  };

  /*
   * criar uma função que atualize o campo "pago" e "dataPagamento" na conta, utilizando a funcao updateDoc com a opcão merge: true
   */
  // import { getFirestore, doc, setDoc } from "firebase/firestore"; 
  // // Your Firebase SDK Initialization code here 
  // const db = getFirestore(app); 
  // const docRef = doc(db, "cities", "p4eZc05QV43InigxALJ"); 
  // const data = { title: "Vancouver", provinceName: "British Columbia", countryCode: "CA" }; 
  // setDoc(docRef, data)
  //  .then(docRef => { 
  //   //console.log("Entire Document has been updated successfully"); 
  // }).catch(error => { 
  //   //console.log(error); 
  // })

  // import { getFirestore, doc, setDoc } from "firebase/firestore";
  //  ... setDoc(docRef, data, { merge:true })
  //  .then(docRef => { 
  //   //console.log("Document Field has been updated successfully); 
  // }).catch(error => { 
  //   //console.log(error); 
  // })


  // updateActiveState = async (id, newStateValue) => {
  //   // //console.log("updateActiveState => id = ", id, " newStateValue = ", newStateValue);
  //   // try {
  //   //   return await updateDoc(doc(db, "contas", id), {
  //   //     metadataactive: newStateValue,
  //   //   });
  //   // } catch (error) {
  //   //   throw error;
  //   // }
  // };

  delete = async (id) => {
    //console.log("id=", id);
    try {
      await this.cancelCobranca(id);

      //Recupera a conta
      let data = await this.getOne(id);
      if (data) {
        //TODO: Se for vinculado a um evento, cancelar a  inscricao
        // if (false && data.vinculo.hasOwnProperty("vincularAoEventoID")) {
        //   let inscricoes = await getDocs(query(collection(db, "inscricoes"), where("vinculo.vincularAoEventoIDE", "==", data.vinculo.vincularAoEventoIDE), where("metadados.active", "==", true)));
        //   let updateInscroesData = false;        
        //   inscricoes.forEach(async (inscricao) => {          
        //     let inscricaoId = inscricao.id;
        //     inscricao = inscricao.data();
        //     inscricao.inscritos.forEach((modalidade) => {
        //       let modalidadesKeys = Object.keys(modalidade);
        //       modalidadesKeys.forEach(async (modalidadeKey) => {
        //         modalidade[modalidadeKey].forEach(async (inscricaoNaModalidade) => {
        //           if (inscricaoNaModalidade.hasOwnProperty("financeiro")) {
        //             if (inscricaoNaModalidade.financeiro.idConta === id) {
        //               inscricaoNaModalidade.financeiro.situacao="Cancelado";
        //               updateInscroesData = true;
        //             }
        //           }
        //           else {
        //             let atletasKeys = Object.keys(inscricaoNaModalidade);
        //             atletasKeys.forEach(async (atletaKey) => {
        //               if (inscricaoNaModalidade[atletaKey].hasOwnProperty("financeiro")) {
        //                 if (inscricaoNaModalidade[atletaKey].financeiro.idConta === id) {
        //                   inscricaoNaModalidade[atletaKey].financeiro.situacao="Cancelado";
        //                   updateInscroesData = true;
        //                 }
        //               }
        //             });
        //           }
        //         });
        //       });
        //     });   
        //     if (updateInscroesData) {
        //       //console.log("contas => delete => inscricao=", inscricao);
        //       // await updateDoc(doc(db, "inscricoes", inscricaoId), inscricao);
        //     }
        //     // updateDoc(doc(db, "inscricoes", inscricaoId), inscricao);
        //   });

        // }
        //console.log("contas => delete => data=", data);

        // if (data.vinculo.hasOwnProperty("idServicoCertificacao")) {
        //   if (data.vinculo.idServicoCertificacao !== "") {
        //     await updateDoc(doc(db, "certificationservice", data.vinculo.idServicoCertificacao), {["metadados.active"]: false}, { merge:true });
        //   }
        // }
        // //console.log("certificacao cancelada - passo 1");
        // let praticante = await getDoc(doc(db, "praticantes", data.vinculo.idEntidade));
        // //console.log("get praticante - passo 2 => praticante:", praticante);
        // praticante = praticante.data();
        // //console.log("get praticante - passo 2B => praticante:", praticante);
        // praticante.historicoCertificacoes = praticante.historicoCertificacoes.map((certificacao) => {
        //   if (certificacao.idServicoCertificacao !== undefined) {
        //     if (certificacao.idServicoCertificacao === data.vinculo.idServicoCertificacao 
        //       && data.taxa.idConta === id) {
        //       certificacao.status = "Cancelado";
        //       return certificacao;
        //     }
        //     else return certificacao;          
        //   }
        //   else return certificacao;          
        // });
        // //console.log("get praticante - passo 3");
        // await updateDoc(doc(db, "praticantes", data.vinculo.idEntidade), praticante);
        // //console.log("certificacao cancelada - passo 4");

        //Cancela a conta
        await updateDoc(doc(db, "contas", id), { ["metadados.active"]: false }, { merge: true })
          .then((retValue) => {
            //console.log("retValue=", retValue);
            return retValue;
          }).catch((error) => {
            //console.log("error=", error);
            throw error;
          });
      }
    } catch (error) {
      if (error.message.includes("Registro não encontrado")) {
      } else {
        //console.log("error=", error);
        throw error;
      }
    };


    // await this.getOne(id)
    //   .then((retData) => {
    //     let data = { ...retData, metadados: { ...retData.metadados, active: false } };
    //     this.update(id, data);
    //   })
    //   .catch((error) => {
    //     if (error.message.includes("Registro não encontrado")) {
    //     } else {
    //       //console.log("error=", error);
    //       throw error;
    //     }
    //   });
  };

  cancelCobranca = async (id) => {
    try {
      this.getOne(id).then((retData) => {
        if (retData) {
          if (retData.hasOwnProperty("bolixInfo")) {
            if (retData.bolixInfo.hasOwnProperty("chargeId")) {
              let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/gnet/canceltransaction";
              axios.post(urlService, { id: retData.bolixInfo.chargeId })
                .then((response) => {
                  //console.log(response);
                }, (error) => {
                  //console.log(error);
                });
            }
          }
        }
      }).catch((error) => {
        throw error;
      });
    } catch (error) {
      if (error.message.includes("Registro não encontrado")) {
      } else {
        //console.log("error=", error);
        throw error;
      }
    };
  };

  abonarCobranca = async (id, justificativa, callbackFunction) => {
    console.log("abonarCobranca => id=", id, " justificativa=", justificativa);
    try {
      this.getOne(id).then((retData) => {
        if (retData) {
          if (retData.hasOwnProperty("bolixInfo")) {
            if (retData.bolixInfo.hasOwnProperty("chargeId")) {
              console.log("abonarCobranca => retData.bolixInfo.chargeId=", retData.bolixInfo.chargeId);
              let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/gnet/canceltransaction";
              axios.post(urlService, { id: retData.bolixInfo.chargeId })
                .then((response) => {
                  //console.log(response);
                  retData.pagamento.pago = true;
                  retData.pagamento.dataPagamento = DateTimeZoneFixToday();
                  retData.pagamento.totalPago = 0;
                  retData.pagamento.totalRecebido = 0;
                  retData.pagamento.desconto = Number(retData.valor);
                  if (justificativa === undefined || justificativa === "") justificativa = "Justificativa não informada.";
                  retData.observacao = retData.observacao === "" ?
                    justificativa :
                    (retData.observacao
                      + (retData.observacao.substring(retData.observacao.length - 1) === "." ? " " : ". ")
                      + justificativa);
                  this.update(id, retData).then((retValue) => {
                    if (callbackFunction !== undefined) {
                      setTimeout(() => {
                        callbackFunction(retData);
                      }, 3500);
                    }
                  }).catch((error) => {
                    throw error;
                  });
                }, (error) => {
                  throw error;
                });
            }
          }
        }
      }
      ).catch((error) => {
        throw error;
      });
    } catch (error) {
      if (error.message.includes("Registro não encontrado")) {
      } else {
        console.log("error=", error);
        throw error;
      }
    };
  };

  createCobranca = async (value, docRef, callbackFunction, errorNotificationCallback) => {
    console.log("docRef.id=", docRef.id);
    console.log("value=", value);
    if (value.vincularAoEventoID === undefined) { // Para não gerar cobrança para inscrições
      let urlService = value.vincularAoEventoID !== undefined ? process.env.REACT_APP_BACKEND_API_URL + "/api/gnet/createbolixbalancete" : process.env.REACT_APP_BACKEND_API_URL + "/api/gnet/createbolix";
      if (process.env.REACT_APP_ENV === "development") console.log("urlService=", urlService);
      const mokking = false; //Todo: False para o ambiente de produção
      if (!mokking) {
        return axios.post(urlService, { id: docRef.id })
          .then((response) => {
            console.log(response);
            if (response.data.code === 200) {
              console.log("response.data.code === 200");
              console.log("response.data=", response.data);
              if (callbackFunction !== undefined && response.data
                && response.data.data && response.data.data.pdf) {
                callbackFunction(docRef.id, response.data.data.pdf.charge);
              }
            } else {
              this.delete(docRef.id);
              if (errorNotificationCallback !== undefined) {
                errorNotificationCallback();
              }
            }
          }, (error) => {
            ConsoleLog.log(error);
          });
      } else {
        if (callbackFunction !== undefined) {
          callbackFunction(docRef.id, "mokking.pdf");
        }
        return true;
      }
    }
  };

  // createCobranca = async (value, docRef) => {
  //   if (value.vinculo.idEntidade === "TRhRghIYvkTs5igtOt6YnoQGAvC3") {
  //     // //console.log("docRef.id=", docRef.id);
  //     // //console.log("value=", value);
  //     // //console.log("value.vinculo.vincularAoEventoID=", value.vinculo.vincularAoEventoID);
  //     // //console.log("process.env.REACT_APP_BACKEND_API_URL:", process.env.REACT_APP_BACKEND_API_URL);
  //     if (value.vincularAoEventoID !== undefined) {
  //       let urlService = value.vincularAoEventoID !== undefined ? process.env.REACT_APP_BACKEND_API_URL + "/api/gnet/createbolixbalancete" : process.env.REACT_APP_BACKEND_API_URL + "/api/gnet/createbolix";
  //       // let urlService = "http://localhost:5000/api/gnet/createbolixbalancete";
  //       //console.log("urlService=", urlService);
  //       axios.post(urlService, {id: docRef.id})
  //       .then((response) => {
  //         //console.log(response);
  //       }, (error) => {
  //         //console.log(error);
  //       });
  //     }
  //   }
  // };

  postergarVencimento = async (id) => {
    try {
      let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/gnet/reschedulecharge";
      if (process.env.REACT_APP_ENV === "development") console.log("urlService=", urlService);
      const mokking = false; //Todo: False para o ambiente de produção
      if (!mokking) {
        return axios.post(urlService, { id: id })
          .then((response) => {
            console.log(response);
            // if (response.data.code === 200) {  
            //   console.log("response.data.code === 200");  
            //   console.log("response.data=", response.data);                   
            //   if (callbackFunction !== undefined && response.data 
            //     && response.data.data && response.data.data.pdf) {
            //     callbackFunction(docRef.id, response.data.data.pdf.charge);
            //   }
            // } else {
            //   this.delete(docRef.id);
            //   if(errorNotificationCallback !== undefined) {
            //     errorNotificationCallback();
            //   }
            // }
          }, (error) => {
            ConsoleLog.log(error);
          });
      }
      //  else {
      //   if (callbackFunction !== undefined) {
      //     callbackFunction(docRef.id, "mokking.pdf");
      //   }
      //   return true;
      // }
    } catch (error) {
      throw error;
    }
  }

  reenviarCobrancaByContaId = async (contaId, email) => {
    console.log("reenviarCobrancaByContaId => contaId=", contaId, "email=", email);

    await this.getOne(contaId)
      .then(async (doc) => {
        console.log("doc=", doc);

        if (email === undefined || email === null || email === "") {
          email = await GenericService.getOne("praticantes", doc.vinculo.idEntidade).then((retData) => {
            if (retData) {
              if (retData.hasOwnProperty("dadosContato")) {
                if (retData.dadosContato.hasOwnProperty("email")) {
                  return retData.dadosContato.email;
                }
              }
            }
          });
        }
        console.log("reenviarCobrancaByContaId => doc.bolixInfo.chargeId=", doc.bolixInfo.chargeId, "email=", email,);
        await this.reenviarCobranca(contaId, email);
      }).catch((error) => {
        console.log(error);
        throw error;
      });
  }

  reenviarCobranca = async (id, email) => {
    try {
      console.log("reenviarCobranca id=", id);
      let idPraticante = "";
      let idCobranca = await this.getOne(id).then((retData) => {
        if (retData) {
          if (retData.hasOwnProperty("bolixInfo")) {
            if (retData.bolixInfo.hasOwnProperty("chargeId")) {
              idPraticante = retData.vinculo.idEntidade;
              return retData.bolixInfo.chargeId;
            }
          }
        }
      });
      console.log("idCobranca=", idCobranca);
      console.log("idPraticante=", idPraticante);
      if (email === undefined) {
        email = await GenericService.getOne("praticantes", idPraticante).then((retData) => {
          if (retData) {
            if (retData.hasOwnProperty("dadosContato")) {
              if (retData.dadosContato.hasOwnProperty("email")) {
                return retData.dadosContato.email;
              }
            }
          }
        });
      }
      console.log("email=", email);

      let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/gnet/resendemail";
      if (process.env.REACT_APP_ENV === "development") console.log("urlService=", urlService);
      const mokking = false; //Todo: False para o ambiente de produção
      // if (!mokking) {
      return axios.post(urlService, { id: idCobranca, email: email })
        .then((response) => {
          console.log(response);
        }, (error) => {
          ConsoleLog.log(error);
        });
      // }
    } catch (error) {
      console.log("reenviarCobranca error=", error);
      throw error;
    }
  }

}

export default new contasService();
