import React, { useEffect, Suspense, lazy } from "react";
const FooterBar = lazy(() => import("../components/FooterBar"));
const Header = lazy(() => import("../components/Header"));
const CookiesNotification = lazy(() => import("../components/cookies/CookiesNotification"));
const HelpdeskStart = lazy(() => import("../components/helpdesk/HelpdeskStart"));
// import "../styles/Layout.css";
// import { useEffect } from "react";
// import { useDispatch } from "react-redux";
const BannerPortals = lazy(() => import("../components/BannerPortals"));
// import { Margin } from "@mui/icons-material";

export default function Layout(props) {
  let currentPath = window.location.pathname;
  // //console.log("currentPath=", currentPath);

  // useEffect(() => {
  //   const host = window.location.host;
  // });
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   const host = window.location.host;
  //   const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
  //   if (arr.length > 0) {
  //     //console.log("arr[0]=", arr[0]);
  //     dispatch({ type: "SUBDOMAIN", payload: arr[0] });
  //   }
  // }, []);

  // //console.log("Passou aqui!");

  return (
    <>
      {/* {console.log("Passou aqui!")} */}
      {/* {console.log("props.subdomain=", props.subdomain)} */}
      <div id="wrap" className="d-flex flex-column">
        <Suspense fallback={<div></div>}>
          <CookiesNotification />
        </Suspense>
        <Suspense fallback={<div></div>}>
          <HelpdeskStart />
        </Suspense>
        <div id="header" className="d-flex flex-column">
          <header>
            <Suspense fallback={<div></div>}>
              <Header subdomain={props.subdomain} />
            </Suspense>
          </header>
          {currentPath !== "/" && !currentPath.includes("/sie") && (
            <div className="d-flex w-100 col-12 sticky-top">
              <Suspense fallback={<div></div>}>
                <BannerPortals showFixed={true} />
              </Suspense>
            </div>
          )}
        </div>
        {/* A altura minima é igual a altura do viewport - altura do header - altura do footer */}
        <div
          id="main"
          className="d-flex"
          style={currentPath !== "/" && !currentPath.includes("/sie") ? { marginTop: "120px", minHeight: "calc(100vh - 56px - 251px)" } : { marginTop: "56px", minHeight: "calc(100vh - 56px - 251px)" }}
        >
          {props.children}
        </div>
        {/* </div> */}
        <div id="footer" className="d-flex">
          <footer>
            <Suspense fallback={<div></div>}>
              <FooterBar subdomain={props.subdomain} />
            </Suspense>
          </footer>
        </div>
      </div>
    </>
  );
}
