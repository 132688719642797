import React, { Fragment } from "react";
import GenericVinculadoService from "../../services/GenericVinculado.service";
import GenericService from "../../services/Generic.service";
import NoticiasSearchComponentVersion2 from "../../components/noticias/NoticiasSearchComponentVersion2";
export default function NoticiasPageVersion2() {
  const CREATERULE = 1100;
  const RETRIEVERULE = 1101;
  const UPDATERULE = 1102;
  const DELETERULE = 1103;
  const STATERULE = 1104;

  return (
    <>
      <NoticiasSearchComponentVersion2
        id="NoticiasRegister"
        dataService={GenericVinculadoService}
        DataServiceNaoVinculado={GenericService}
        collectionName="conteudos"
        title="Notícias"
        help={[
          "Para criar um notícia clique em [Adicionar notícia].",
          "Para editar um notícia clique sobre a icone do lápis ao lado da notícia desejada.",
        ]}
        navPath={[]}
        urlCRUD="/noticias/addnoticia"
        urlView="/noticias/noticia"
        tituloCRUD="Adicionar notícia"
        searchLabel="Pesquisar por notícia"
        searchTarger="searchableName"
        addLabel="Adicionar notícia"
        CREATERULE={CREATERULE}
        RETRIEVERULE={RETRIEVERULE}
        UPDATERULE={UPDATERULE}
        DELETERULE={DELETERULE}
        STATERULE={STATERULE}
        columns={[
          {
            header: "Notícia",
            accessor: ["title"],
            width: "auto",
            dataType: "String",
          },
          {
            header: "Versão",
            accessor: ["metadados", "version"],
            width: "90px",
            dataType: "Numeric",
          },
          // {
          //   header: "Atualizado em",
          //   accessor: ["metadados", "date"],
          //   width: "135px",
          //   dataType: "TimeStamp",
          // },
          {
            header: "Situação",
            accessor: ["metadados", "active"],
            width: "90px",
            dataType: "Boolean",
            defaults: ["Ativo", "Inativo"],
          },
        ]}
      />
    </>
  );
}
